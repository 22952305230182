import React, {createContext, useContext, useEffect} from 'react'
import {AnimatePresence, motion} from "framer-motion";
import {AiOutlineClose} from "react-icons/ai";
import { POPUP_MEDIUM, POPUP_SMALL, POPUP_TYPE_SIDE } from '../../constants';
import SidePopup from './SidePopup';
import { BasePopupContext } from '../../hooks/usePopup';

const BasePopup = () => {

    //extracting state from context
    const {popupState} = useContext(BasePopupContext);

    return (
            <AnimatePresence>
                {
                    popupState.map((item, index) =>(
                        item.type === POPUP_TYPE_SIDE ?
                            <SidePopup 
                                key={index}
                                data={{
                                    ...item,
                                    index
                                }}
                            />
                        :
                            <PopupContent 
                                key={index}
                                index={index}
                                data={item}
                            />
                    ))
                }
            </AnimatePresence>
    )
}

const PopupContent = ({data, index}) => {
const sizeClass = data.size === POPUP_SMALL ? "w-full md:w-96 lg:w-6/12 xl:w-5/12 2xl:w-5/12 max-w-[700px]" : data.sizeClass === POPUP_MEDIUM ? "w-full lg:w-7/12 xl:w-6/12 2xl:w-5/12 max-w-[700px]" : "w-10/12 max-w-container"
// this is a helper component which sets the base for a modal
const variants = {
    initial: {
        // opacity: 0,
        // scale: 0.8,
        x: "100%"
    },
    enter: {
        x: "0%",
        transition: {
            duration: 0.2,
            ease: "easeOut"
        }
    },
    exit: {
        // opacity: 0,
        // scale: 0.8,
        x: "100%"
    }
}

const {closePopup} = useContext(BasePopupContext);
    return(
        <motion.div 
        initial="initial"
        animate="enter"
        exit="exit"
        key={"main-popup"}
        variants={{
            initial: {
                opacity: 0,
            },
            enter: {
                opacity: 1,
                transition: {
                    duration: 0.1,
                    ease: "easeOut",
                    staggerChildren: 0.5,
                    when:"beforeChildren"
                }
            },
            exit: {
                opacity: 0,
            },
        }}
        className={`bg-gradient-to-l from-primary via-primary/60 to-black/50 bg-opacity-50 left-0 top-0 fixed h-screen w-screen flex items-start  justify-end`} style={{zIndex: `${1000 + index}`}}>
            <motion.div
            variants={variants}
            className={`${sizeClass} border-2 border-white overflow-y-auto h-full bg-white shadow-lg rounded-tl-[0] rounded-bl-[0]  relative`}>
                {/* header */}
                <div className="rounded-lg rounded-b-none pl-10 px-10 p-4 flex items-center justify-between border-b-2 border-gray-200">
                    <p className="text-xl font-medium text-t-light">
                        {data.heading}
                    </p>
                    <div className={`text-t-light text-2xl cursor-pointer ${data.hideDefaultClose && "hidden"}`} onClick={closePopup}>
                        <AiOutlineClose/>
                    </div>
                </div>
                {/* header */}
                
                <div className={data.contained ? "px-10 py-8" : ""}>
                    {data.component}
                </div>
            </motion.div>
        </motion.div>
    )
}

export default BasePopup
