import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faXmark } from "@fortawesome/free-solid-svg-icons";
import development1 from "../../assets/images/development1.png";
import development2 from "../../assets/images/development2.png";
import development3 from "../../assets/images/development3.png";
import development4 from "../../assets/images/development4.png";
import development5 from "../../assets/images/development5.png";

import investment1 from "../../assets/images/investment1.png";
import investment2 from "../../assets/images/investment2.png";

import { BiX } from "react-icons/bi";
import { BiPlus } from "react-icons/bi";

import { AnimatePresence, motion } from "framer-motion";

const DevelopmentExperiences = [
  {
    id: 0,
    heading: "Cardiovascular",
    images: (
      <>
        <img
          src={development1}
          alt="pic"
          className=" mb-4 mx-auto object-cover"
        />
        <img src={development2} alt="" className=" mx-auto object-cover" />
      </>
    ),
    description: (
      <>
        <p>
          <b>Epygon</b> company was founded at the end of 2012 based on a patent
          by <b>Enrico Pasquino</b> and <b>Marcio Scorsin</b>, with the mission
          of developing an innovative mitral bioprosthetic valve implantable
          through a minimally invasive technique.
        </p>
        <p>
          This valve is designed specifically for patients with mitral valve
          stenosis-regurgitation due to left ventricular pathology. The{" "}
          <b>Epygon</b> mitral valve has a unique Nitinol stent asymmetric
          structure, featuring a “D-shaped” inflow and a single bovine
          pericardial leaflet (monoleaflet) that facilitates a physiological
          ventricular “Vortex” flow.
        </p>
        <p>
          Restoring the Vortex supports faster recovery of left ventricular
          function. The Epygon valve implant can be completed within minutes via
          a minimally invasive thoracotomy and access through the apex of the
          left ventricle. The project has successfully completed development and
          is advancing through the clinical phase, with a series of implants
          already performed in Europe.
        </p>
      </>
    ),
  },
  {
    id: 1,
    heading: "Cardiovascular",
    images: (
      <img src={development3} alt="pic" className="mx-auto object-cover" />
    ),
    description: (
      <>
        <p>
          <b>AorticLab</b> was founded in 2016, based on patents provided by its
          Founding Partners <b>(Enrico Pasquino, Franco Osta</b>, and{" "}
          <b>Marco Vola)</b>, with the purpose of developing a dual-frequency
          ultrasound transcatheter device to restore aortic valve function and
          delay the need for bioprosthetic implantation.
        </p>
        <p>
          The procedure includes the use of an embolic protection filter to
          safeguard the patient from cerebral ischemic events. Currently, the{" "}
          <b>TDD</b> ultrasound device is in the preclinical phase and nearing
          its FIH (First In Human) trial, while the <b>FLOWer</b> embolic
          protection filter has also been developed independently and is used
          during TAVI (Transcatheter Aortic Valve Implantation) bioprosthetic
          valve procedures. The <b>FLOWer</b> device received CE mark
          certification, under MDR, in February 2024 and is currently in
          clinical use in Europe, with expansion plans for the U.S.
        </p>
      </>
    ),
  },
  {
    id: 2,
    heading: "R&D and Manufacturing",
    images: (
      <img src={development4} alt="pic" className="mx-auto object-cover" />
    ),
    description: (
      <>
        <p>
          <b>MDE</b> (Medical Device Engineering) was co-founded by{" "}
          <b>Myra Medical</b> in 2018 with the mission of delivering advanced
          technologies for next-generation medical devices.
        </p>
        <p>
          The company, now with 30 employees, designs and prototypes devices and
          specializes in multilumen and microcatheter extrusion technology, 3D
          printing of polymers and metals, as well as assembly and manufacturing
          techniques for both active and non-active devices. At its ISO
          13485-certified facility in Villa Carcina (Brescia, Italy), spanning
          over 2,500 square meters, MDE includes more than 300 square meters of
          clean room space to support the production of complex biomedical and
          transcatheter devices.
        </p>
      </>
    ),
  },
];

const investment = [
  {
    id: 0,
    heading: "Oncology",
    description: (
      <>
        <p>
          <b>Synergys</b> is a project, co-founded by <b>Myra Medical</b> ,
          focused on the treatment of soft and hard tissue tumors using devices
          that emit Radio Frequency (RF), Microwaves (MW), and Laser (LS) energy
          through interventional radiological procedures. The uniqueness of the
          project lies in the use of emission needles for RF or MW, or optical
          fibers for laser emission.
        </p>
        <p>
          The <b>Synergys</b> device generator can emit multiple energies
          simultaneously (e.g., combining RF+MW) and modulate the
          electromagnetic emission field through a patented telescopic system.
          This allows for the treatment of tumor lesions by adjusting the energy
          field to their size, significantly reducing recurrence rates.
          Recently, within the Synergys project, these patented technologies
          have also been applied to the development of an innovative RF or MW
          probes for the treatment of chronic lumbar pain.
        </p>
      </>
    ),
    images: (
      <img src={investment1} alt="pic" className="mx-auto object-cover" />
    ),
  },
  {
    id: 1,
    heading: "R&D production",
    description: (
      <>
        <p>
          <b>E2F</b> is a project co-funded by <b>Myra Medical</b>, aimed at
          treating chronic total occlusions (CTO) in peripheral vessels caused
          by atherosclerotic lesions. This transcatheter device emits
          dual-frequency ultrasound waves with a beat frequency effect and is
          also equipped with an ultrasonic micro-percussor on the tip, enabling
          both the opening of the vessel lumen and micro-fracturing of calcified
          atherosclerotic plaque. The project is patent-protected, and
          Industrial Partners or Investors are being sought to support its
          development.
        </p>
      </>
    ),
    images: (
      <img src={investment2} alt="pic" className="mx-auto object-cover" />
    ),
  },
];

// Variants for each individual Stats component
const itemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// Variants for the parent container (Vision)
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

function Portfolio() {
  const [selectedSection, setSelectedSection] = useState(false);
  const DevelopmentExperiencesRef = useRef(null);
  const investmentRef = useRef(null);
  const investmentHeadingRef = useRef(null); // if first one is open

  function scrollToSmoothly(pos, time) {
    var currentPos = window.pageYOffset;
    var start = null;
    if (time == null) time = 500;
    pos = pos + 1;
    time = time + 1;
    window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start;
      var progress = currentTime - start;
      if (currentPos < pos) {
        window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
      } else {
        window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
      }
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    });
  }

  const toggleSection = (section) => {
    let previousState = selectedSection;
    if (selectedSection === section) {
      if (section === "DevelopmentExperiences") {

        const element = document.getElementById("DevelopmentExperiences");
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = window.scrollY + elementPosition;

        scrollToSmoothly(offsetPosition, 500);

      } else if (section === "investment") {

        const element = document.getElementById("investment");
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = window.scrollY + elementPosition;

        scrollToSmoothly(offsetPosition, 500);
      }

      setSelectedSection(false); ///this is doing false the state
    } else {
      if (previousState === "DevelopmentExperiences") {

        const element = document.getElementById("investment");
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = window.scrollY + elementPosition;

        scrollToSmoothly(offsetPosition, 500);
      }
      setSelectedSection(section);
    }
  };

  return (
    <motion.div
      id="portfolio"
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      className="py-10 md:py-16 lg:py-24   text-clr-primary space-y-12 font-default mb-10           px-3 xs:px-5 md:px-16 xl:px-24 3xl:px-52"
    >
      <motion.p
        variants={itemVariants}
        viewport={{ once: true, amount: 0.2 }}
        className="text-[1.1rem] md:text-[1.25rem] lg:text-[1.4rem] xl:text-[1.9rem] 3xl:text-[2.2rem] font-semibold md:font-bold font-barlow md:w-[75%] lg:w-[80%] mx-auto"
      >
        Over the past 10 years, Myra Medical has contributed to the founding of
        some companies in the biomedical field, dedicated to the development of
        medical devices. The concepts behind these projects were conceived and
        patented by Myra Medical and their partners.
      </motion.p>

      <motion.div
        variants={itemVariants}
        viewport={{ once: true, amount: 0.2 }}
        id="portfolio"
        className=""
      >
        <h1
          ref={DevelopmentExperiencesRef}
          id="DevelopmentExperiences"
          className="text-[2rem] xs:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] 3xl:text-[4.3rem] font-default font-black text-center mt-8 mb-2  xl:w-[80%] 3xl:w-[70%] mx-auto
                    my-4 md:my-6 lg:my-8 xl:my-14"
        >
          On-going development experiences
        </h1>

        {/* Conditionally render DevelopmentExperiences */}
        <AnimatePresence mode="wait">
          {selectedSection === "DevelopmentExperiences" && (
            <motion.div
              initial={{
                height: 0,
              }}
              exit={{
                height: 0,
              }}
              animate={{
                height: "auto",
              }}
              transition={{
                duration: 0.8,
                ease: "easeInOut",
              }}
              key={selectedSection}
              className={`overflow-hidden `}
            >
              {/**/}
              <ul className="space-y-6  py-4 md:py-6 lg:py-10 ">
                {DevelopmentExperiences.map((item) => (
                  <li
                    key={item.id}
                    //
                    className="pb-4 gap-10 flex flex-col md:flex-row items-start "
                  >
                    {/* Left - Image (responsive 40% width) */}
                    <div className="w-full md:w-2/5 lg:w-[25%]">
                      {item.images}
                    </div>
                    {/* Right - Heading and Description (60% width) */}
                    <div className="w-full md:w-3/5 lg:w-[75%] space-y-3">
                      <h2 className="text-[1.2rem] md:text-[1.4rem] lg:text-[1.7rem] font-bold">
                        {item.heading}
                      </h2>
                      <div className="space-y-2 text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem]">
                        {item.description}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Toggle button for DevelopmentExperiences */}
        <div className="xl:w-[80%] 3xl:w-[70%] mx-auto flex items-center justify-center h-[2px] bg-clr-separation">
          <span
            className={` text-[1.5rem] md:text-[2rem] xl:text-[2.7rem] flex items-center justify-center rounded-full w-[28px] h-[28px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] xl:h-[55px] xl:w-[55px] cursor-pointer
              ${
                selectedSection === "DevelopmentExperiences"
                  ? "bg-white text-clr-primary lg_sm:hover:text-white lg_sm:hover:bg-clr-primary border-2 border-clr-primary"
                  : "text-white bg-clr-primary lg_sm:hover:bg-white lg_sm:hover:text-clr-primary lg_sm:hover:border-2 lg_sm:border-clr-primary"
              }
              `}
            onClick={() => toggleSection("DevelopmentExperiences")}
          >
            {/* <FontAwesomeIcon
              icon={
                selectedSection === "DevelopmentExperiences" ? faXmark : faPlus
              }
            /> */}
            {
              selectedSection === "DevelopmentExperiences"
              ?
              <BiX />
              :
              <BiPlus />
            }
          </span>
        </div>
      </motion.div>

      <motion.div
        variants={itemVariants}
        viewport={{ once: true, amount: 0.2 }}
        className=""
      >
        <div
          ref={investmentRef}
          id="investmentDiv"
          className="space-y-4 xl:w-[80%] 3xl:w-[70%] mx-auto my-4 md:my-6 lg:my-8 xl:my-14"
        >
          <h1
            ref={investmentHeadingRef}
            id="investment"
            className="text-[2rem] xs:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] 3xl:text-[4.3rem] font-default font-black text-center mt-8 mb-2"
          >
            Investment in new Projects
          </h1>
          <p className="text-left md:text-center font-barlow text-[1.1rem] md:text-[1.25rem] lg:text-[1.4rem] xl:text-[1.9rem] 3xl:text-[2.2rem] uppercase">
            Myra Medical is also a co-founder of development projects in the
            biomedical field, which have not yet been transformed into startups
            but are supported by significant patents to protect the underlying
            ideas. If you are interested in any of these projects, either in
            terms of funding or co-development, we are open to discussions.
          </p>
        </div>

        {/* Conditionally render investment */}
        <AnimatePresence mode="wait">
          {selectedSection === "investment" && (
            <motion.div
              initial={{
                height: 0,
              }}
              exit={{
                height: 0,
              }}
              animate={{
                height: "auto",
              }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
              className={`overflow-hidden`}
            >
              <ul className="space-y-6 py-4 md:py-6 lg:py-10">
                {investment.map((item) => (
                  <li
                    key={item.id}
                    className="pb-4 flex flex-col md:flex-row items-start gap-6"
                  >
                    {/* Left - Image */}
                    <div className="w-full md:w-2/5 lg:w-[25%]">
                      {item.images}
                    </div>
                    {/* Right - Heading and Description */}
                    <div className="w-full md:w-3/5 lg:w-[75%] space-y-3">
                      <h2 className="text-[1.1rem] md:text-[1.25rem] lg:text-[1.4rem] xl:text-[1.9rem] 3xl:text-[2.2rem] font-bold">
                        {item.heading}
                      </h2>
                      <div className="space-y-2 text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem] ">
                        {item.description}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Toggle button for investment */}
        <div className="xl:w-[80%] 3xl:w-[70%] mx-auto flex items-center justify-center h-[1.4px] bg-clr-separation">
          <span
            className={`text-[1.1rem] md:text-[1.5rem] xl:text-[2rem] flex items-center justify-center rounded-full w-[28px] h-[28px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] xl:h-[55px] xl:w-[55px] cursor-pointer
              ${
                selectedSection === "investment"
                  ? "bg-white text-clr-primary lg_sm:text-white lg_sm:bg-clr-primary border-2 border-clr-primary"
                  : "text-white bg-clr-primary lg_sm:hover:bg-white lg_sm:hover:text-clr-primary lg_sm:hover:border-2 lg_sm:border-clr-primary"
              }
               `}
            onClick={() => toggleSection("investment")}
          >
            <FontAwesomeIcon
              icon={selectedSection === "investment" ? faXmark : faPlus}
            />
          </span>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Portfolio;
