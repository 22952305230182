import React, { createContext, useEffect, useState } from 'react'
import { initialPopupState } from '../../constants';

export const BasePopupContext = createContext(null);

const PopupProvider = ({children}) => {
    const [popupState, setPopupState] = useState([]);
    const openPopup = (params) => setPopupState((old) => [...old, {
        ...initialPopupState,
        ...params
    }]);
    const closePopup = () =>
        setPopupState((old) =>
            old.filter((item, index) => (index !== old.length - 1 ? true : false))
    );
    const closeAllPopup = () => setPopupState([]);

    const popup_data = {
        popupState,
        setPopup: openPopup,
        closePopup,
        closeAllPopup,
    };

    useEffect(() => {
        if(popupState.length > 0){
            document.body.style.overflowY = "hidden"
        }else{
            document.body.style.overflowY = "auto"
        }
    }, [popupState])

    return (
        <BasePopupContext.Provider value={popup_data}>
            {children}
        </BasePopupContext.Provider>
    )
}


export default PopupProvider