import React from "react";
import { motion } from "framer-motion";

// Variants for each individual Stats component
const itemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// Variants for the parent container (Vision)
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

function TestAndDevelopment() {
  return (
    <div
      id="TalentDevelopment"
      className="bg-[#EAEEF0] py-20 font-barlow text-left lg:text-center text-clr-primary                      px-3 xs:px-5 md:px-16 xl:px-24 3xl:px-52 "
    >
      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, 2: 0.2 }}
        className=" md:w-[75%] mx-auto last:mx-auto"
      >
        <motion.h1
          variants={itemVariants}
          viewport={{ once: true, amount: 0.2 }}
          className="text-[2.4rem] md_sm:text-[2.5rem] lg:text-[2.8] xl:text-[3rem] 3xl:text-[3.4rem] font-bold mb-3 md:mb-4 lg_sm:mb-5 lg:mb-6 xl:mb-5 "
        >
          Talent Development
        </motion.h1>
        <motion.p
          variants={itemVariants}
          viewport={{ once: true, amount: 0.2 }}
          className="text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem]  mb-2 md:mb-2 lg:mb-3 xl:mb-4 tracking-wide font-medium"
        >
          Thanks to our team of highly qualified professionals across various
          essential consulting areas in medical device development, Myra Medical
          serves as an excellent talent developer in applied biomedical device
          science. Our company is currently developing a talent training program
          across multiple disciplines, including Mechanical and Biomedical
          Engineering, Biology and Chemistry, and Pharmaceutical Technologies.
          Each year, a limited number of candidates will be admitted to our
          company following a rigorous selection process.
        </motion.p>
        <motion.p
          variants={itemVariants}
          viewport={{ once: true, amount: 0.2 }}
          className=" text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem] font-bold  mb-2 md:mb-2 lg:mb-3 xl:mb-4  text-center"
        >
          If you are interested in candidate yourself, please write to
        </motion.p>
        <motion.div
          variants={itemVariants}
          viewport={{ once: true, amount: 0.2 }}
          className="flex items-center justify-center">
          <motion.a

            href="mailto:info@myramedical.com"
            className="text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem] underline underline-offset-2 font-bold text-center"
          >
            info@myramedical.com
          </motion.a>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default TestAndDevelopment;
