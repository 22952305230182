import React, { useEffect, useRef } from "react";
import location from "../../assets/images/location2.svg";
import person from "../../assets/images/person.png";
import personSpeaker from "../../assets/images/personSpeaker.png";
import card from "../../assets/images/idCard.png";
import logo from "../../assets/images/logo3.svg"
import { Link } from "react-router-dom";
import { usePreferredLanguage } from "@uidotdev/usehooks";

function CookiePolicyPage() {
  const isFirstRender = useRef(true)
  
  useEffect(() => {
    if(isFirstRender.current){
      window.scrollTo(0, 0);
      isFirstRender.current = false

      return
    }

  }, []);

  return (
    <div className="pb-3 xs:pb-5 md:pb-16 xl:pb-24 3xl:pb-52 px-3 xs:px-5 md:px-16 xl:px-24 3xl:px-52 list-inside space-y-5 cookiePolicyText">
        <div className="h-[60px] md:h-[74px] 3xl:h-[120px] flex items-center" >
            <Link to="/">
                <img src={logo} alt="" />
            </Link>
        </div>
      <p className="cookiePolicyHeading">Cookie Policy -</p>
      <h1 className="text-[1.1rem] md:text-[1.25rem] lg:text-[1.4rem] xl:text-[1.9rem] 3xl:text-[2.2rem] font-bold">
        NOTICE CONCERNING THE PROCESSING OF PERSONAL DATA OF ONLINE USERS -
        COOKIEPOLICY PURSUANT TO REGULATION (EU) 2016/679 (&quot;GDPR&quot;)
      </h1>

      <div className="space-y-3">
        {/* first table */}
        <div className="min-w-[345px] overflow-auto">
            <table className="w-full">
            <tbody className="bg-blue-300">
                <tr className="border-[0.2rem] border-clr-primary">
                <th
                    scope="row"
                    className="min-w-20 border-[0.2rem] border-clr-primary"
                >
                    <img src={person} alt="" className="w-60 h-auto" />
                </th>
                <td className="border-[0.2rem] border-clr-primary px-4 cookiePolicySubHeading">
                    Stefano Pasquino
                </td>
                <td className="border-[0.2rem] border-clr-primary px-4 cookiePolicySubHeading">
                    Myra Medical sarl
                </td>
                </tr>
                <tr className="border-[0.2rem] border-clr-primary">
                <th
                    scope="row"
                    className="w-20 border-[0.2rem] border-clr-primary"
                >
                    <img src={personSpeaker} alt="" className="w-60 h-auto" />
                </th>
                <td className="px-4 border-[0.2rem] border-clr-primary cookiePolicySubHeading">
                    Stefano Pasquino (DPO)
                </td>
                <td className="px-4 border-[0.2rem] border-clr-primary">
                    The Data Protection Officer can be contacted at
                    info@myramedical.com
                </td>
                </tr>
            </tbody>
            </table>
        </div>

        <div className="flex border-[0.2rem] border-clr-primary">
          <img src={card} alt="" className="min-w-11 max-w-32 h-auto px-4 bg-blue-200" />
          <div className="flex flex-col w-full border-l-[0.2rem] border-clr-primary">
            <p className="py-1 md:pt-3 border-b-[0.2rem] border-clr-primary font-semibold px-6 bg-blue-200">
              PERSONAL DATA PROCESSED
            </p>
            <p className="py-1 md:pt-3  px-6">Navigation data</p>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div>
          <h1 className="cookiePolicyHeading">WHAT COOKIES ARE</h1>
          <p className="cookiePolicyText">
            Cookies are data created by a server that are stored in text files
            on the hard disk of your computer or on any device used by you to
            access the Internet (smartphone, tablet) and make it possible to
            collect information on your navigation on the website.
          </p>
          <p className="cookiePolicyText">
            Cookies can be stored permanently on your computer and have a
            variable duration (so-called persistent cookies), but they can also
            disappear when you close your browser or have a limited duration
            (so-called session cookies).
          </p>
          <p className="cookiePolicyText">
            Cookies may be set by the website you are visiting (so-called first
            party cookies) or may be set by other websites (so-called third
            party cookies).
          </p>
        </div>

        <div className="space-y-4">
          <h1 className="cookiePolicyHeading">HOW WE USE COOKIES</h1>
          <div className="space-y-2">
            <h3 className="cookiePolicySubHeading">Technical cookies</h3>
            <ol className="list-lowerAlpha pl-6 space-y-1" >
              <li className="cookiePolicyText">
                <span className="underline">session/navigation cookies: </span>
                the use of so-called session cookies (which are not persistently
                stored on the user&#39;s computer and disappear when the browser
                is closed) is strictly limited to the transmission of session
                identifiers (consisting of random numbers generated by the
                server) necessary to enable the safe and efficient exploration
                of the site and its applications. The so-called session cookies
                used in the site avoid the use of other IT techniques
                potentially prejudicial to the confidentiality of users&#39;
                browsing and do not allow the acquisition of personal data
                identifying the user. They do not require the user&#39;s
                consent.
              </li>
              <li className="cookiePolicyText">
                <span className="underline">persistent cookies: </span>
                persistent cookies remain on the device even after leaving the
                website, until the user deletes them or until their expiry date
                is reached. The site creates this type of cookie and stores them
                on the device so that they can be read when the user visits the
                site again. This allows, for example, the preferences set (e.g.
                storage of registered users...) to be found on subsequent visits
                to the site. They do not require your consent.
              </li>
            </ol>
          </div>

          <div className="space-y-2">
            <p className="cookiePolicyText">
              <span className="cookiePolicySubHeading">Cookie Analytics:</span>{" "}
              cookies installed to collect aggregate information of a mainly
              statistical nature (how long you stay on a web page, number of
              visitors to the site, etc.).
            </p>
            <ol className="list-lowerAlpha pl-6 space-y-1">
              <li className="">
                <span className="underline">
                  First-party analytical cookies:
                </span>
                these are similar to technical cookies in that they are
                installed directly by the site you are visiting to improve its
                usability. They do not require your consent.
              </li>
              <li className="">
                <span className="underline">
                  Third-party analytical cookies:
                </span>
                analytical cookies created and installed by third parties to
                analyse users' browsing in order to produce statistics on visits
                or to improve the content offering, to show similar products and
                to place targeted advertising on web pages, if necessary. The
                system used is Google Analytics, which is a web analysis service
                provided by Google LLC ("Google"). Google Analytics, like other
                systems, uses 'cookies' to enable the website to analyse how
                users use the site. The information generated by the cookie
                about your use of the website (including your IP address, which
                is masked) will be transmitted to and stored by Google on
                servers in the United States. Google will use this information
                for the purpose of evaluating your use of the website, compiling
                reports on website activity for website operators, and providing
                other services relating to website activity and internet usage.
                Google may also transfer this information to third parties where
                required to do so by law, or where such third parties process
                the information on Google's behalf. You may refuse the use of
                cookies by selecting the appropriate settings on your browser,
                but this may prevent you from using the full functionality of
                this website. Since the IP address is masked, the use of such
                cookies does not require your consent as they fall under the
                definition of technical cookies.
              </li>
            </ol>
            <p className="cookiePolicyText">
              <span className="cookiePolicySubHeading">Profiling cookies:</span>{" "}
              in general, these are cookies used to track the user&#39;s
              browsing on the Internet and create profiles on their tastes,
              habits, choices, etc. With these cookies, advertising messages can
              be sent to the user&#39;s terminal in line with the preferences
              already expressed by the same user when browsing online.
            </p>
          </div>
        </div>

        <div>
          <h1 className="cookiePolicyHeading">COOKIE MANAGEMENT</h1>
          <p className="cookiePolicyText">
            When accessing the homepage of the Site, there is a banner
            containing a short information notice on cookies. The same informs
            the user of the meaning of its actions. In particular, it informs
            him/her that the Site uses technical cookies necessary for its
            operation and analytical cookies, also from third parties,
            anonymised for the performance of aggregate statistical analyses.
            For this reason, the manifestation of consent is not necessary and
            by closing the banner the same are installed by default.
          </p>
        </div>

        <div className="cookiePolicyText">
          <h1 className="cookiePolicyHeading">HOW TO CHANGE COOKIE SETTINGS</h1>
          <p className="cookiePolicyText">
            Most Internet browsers are initially set to accept cookies
            automatically. This means that the user has the possibility, at any
            time, to set his or her browser to accept all cookies, only some, or
            to reject them by disabling their use by sites. In addition, the
            user can normally set his browser preferences in such a way as to be
            notified each time a cookie is stored on his computer. Finally, at
            the end of each browsing session, the user can delete the cookies
            collected from the hard disk of his device. If you wish to delete
            the cookies installed in the cookies folder of the browser you are
            using, please remember that each browser has different procedures
            for managing the settings.
          </p>


          <div className="cookiePolicyText space-y-4">
            <p className="cookiePolicyText">
                By clicking on the links below, the user can obtain specific
                instructions for some of the main browsers:
            </p>
            <p className="cookiePolicyText">
                <span>Microsoft Edge: </span>
                <a
                    className="hover:underline cursor-pointer" 
                href="https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies"
                target="_blank"
                >
                https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-
                cookies
                </a>
            </p>
            <p>
                <span>Google Chrome: {" "}</span>
                <a
                    className="hover:underline cursor-pointer"
                href="https://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647&amp;p=cpn_cookies"
                target="_blank"
                >
                https://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647&amp;p=cpn_cookies
                </a>
            </p>
            <p>
                <span>Mozilla Firefox: {" "}</span>
                <a
                    className="hover:underline cursor-pointer"
                href="http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies"
                target="_blank"
                >
                http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies
                </a>
            </p>
            <p>
                <span>Apple Safari: {" "}</span>
                <a
                    className="hover:underline cursor-pointer" 
                href="http://docs.info.apple.com/article.html?path=Safari/5.0/en/9277.html"
                target="_blank"
                >
                http://docs.info.apple.com/article.html?path=Safari/5.0/en/9277.html
                </a>
            </p>
          </div>
        </div>

        <div className="cookiePolicyText space-y-4">
          <p>
            If the user does not wish to receive Google Analytics cookies,
            he/she can do so by going to:
          </p>
          <a className="hover:underline cursor-pointer" href="https://tools.google.com/dlpage/gaoptout/" target="_blank">
            https://tools.google.com/dlpage/gaoptout/
          </a>
          <p>
            If you would like to learn more about cookies in general, please
            visit
          </p>
          <a className="hover:underline cursor-pointer"  href="http://www.allaboutcookies.org">
            http://www.allaboutcookies.org
          </a>
          <p>
            If you want to learn more about behavioural advertising and online
            privacy, please visit
          </p>
          <a className="hover:underline cursor-pointer"  href="http://www.youronlinechoices.com">
            http://www.youronlinechoices.com
          </a>
        </div>

        <div className="min-w-[345px] md:min-w-full overflow-x-auto">
            <table className="w-max md:w-full">
            <thead>
                <tr className="[&>*]:w-[10%] text-center [&>*]:md:p-2 [&>*]:border-2 [&>*]:border-clr-primary">
                <th>Cookie </th>
                <th>Type</th>
                <th>Purpose</th>
                <th>First/Third Party</th>
                <th>Legal Basis</th>
                <th>Retention</th>
                </tr>
            </thead>
            <tbody>
                <tr className="text-center [&>*]:md:p-2 [&>*]:border-2 [&>*]:border-clr-primary">
                <td>_gid</td>
                <td>Analytics</td>
                <td className="text-left">
                    It records a unique ID used to generate statistical data on how
                    the visitor uses the website.
                </td>
                <td>Third party (Google Analytics)</td>
                <td>No consent required (IP anonymisation )</td>
                <td>24 Hours</td>
                </tr>
                <tr className="text-center [&>*]:md:p-2 [&>*]:border-2 [&>*]:border-clr-primary">
                <td>_gid</td>
                <td>Analytics</td>
                <td className="text-left">
                    It records a unique ID used to generate statistical data on how
                    the visitor uses the website.
                </td>
                <td>Third party (Google Analytics)</td>
                <td>No consent required (IP anonymisation )</td>
                <td>2 years</td>
                </tr>
                <tr className="text-center [&>*]:md:p-2 [&>*]:border-2 [&>*]:border-clr-primary">
                <td>_gid</td>
                <td>Analytics</td>
                <td className="text-left">
                    Utilizzato da Google Analytics per limitare la frequenza delle
                    richieste
                </td>
                <td>Third party (Google Analytics)</td>
                <td>No consent required (IP anonymisation )</td>
                <td>1 year</td>
                </tr>
            </tbody>
            </table>
        </div>

        <div>
          <h1 className="cookiePolicyHeading">CONFERRING OF DATA</h1>
          <p className="cookiePolicyText">
            Browsing data are processed by the Data Controller at the same time
            as navigation by installing technical and first-party analytical
            cookies: these data are necessary to ensure correct navigation
            within the site.
          </p>
        </div>

        <div className="cookiePolicyText">
          <h1 className="cookiePolicyHeading">DATA RECIPIENTS</h1>
          <p >
            The Data may be communicated to external parties acting as data
            controllers, by way of example, authorities and supervisory and
            control bodies and, in general, public or private parties entitled
            to request the Data.
          </p>
          <p>
            The Data may be processed, on behalf of the Data Controller, by
            external parties designated as data processors (pursuant to Article
            28 of the GDPR), who carry out specific activities on behalf of the
            Data Controller, by way of example, companies that carry out the
            management and/or maintenance service of the Company&#39;s website,
            but also parties that provide services for the management of the
            information system and telecommunications networks.
          </p>
        </div>

        <div className="cookiePolicyText">
          <h1 className="cookiePolicyHeading">
            SUBJECTS AUTHORISED TO PROCESS
          </h1>
          <p>
            The data may be processed by employees and/or collaborators of the
            Controller and/or the Manager entrusted with the pursuit of the
            aforementioned purposes, who have been expressly authorised to
            process them and who have received appropriate operating
            instructions.
          </p>
        </div>

        <div className="cookiePolicyText">
          <h1 className="cookiePolicyHeading">
            TRANSFER OF PERSONAL DATA TO COUNTRIES OUTSIDE THE EUROPEAN UNION
          </h1>
          <p>
            Data may be processed by Data Processors that are also based in
            countries outside the European Union, whose level of data protection
            has been deemed adequate by the European Commission pursuant to
            Article 45 of the GDPR.
          </p>
          <p>
            Your personal data may also be transferred following the signing of
            the Standard Contractual Clauses provided for in Article 46(2)(c) of
            the GDPR.
          </p>
        </div>

        <div className="cookiePolicyText">
          <h1 className="cookiePolicyHeading">
            RIGHTS OF THE DATA SUBJECT - COMPLAINT TO THE SUPERVISORY AUTHORITY
          </h1>
          <p>
            By contacting the Data Controller, by e-mail at
            (info@myramedical.com), data subjects may request from the Data
            Controller access to the data concerning them, their deletion, the
            rectification of inaccurate data, the integration of incomplete
            data, the restriction of processing in the cases provided for by
            Article 18 GDPR, as well as the objection to processing, for reasons
            related to their particular situation, in cases of legitimate
            interest of the Data Controller.
          </p>
          <p>
            In addition, where the processing is based on consent or contract
            and is carried out by automated means, data subjects have the right
            to receive the data in a structured, commonly used and
            machine-readable format and, if technically feasible, to transmit
            them to another data controller without hindrance.
          </p>
          <p>
            Data subjects have the right to lodge a complaint with the competent
            supervisory authority in the Member State where they habitually
            reside or work or in the State where the alleged infringement
            occurred.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicyPage;
