import React from "react";
import { Formik, useField, Form, useFormikContext } from "formik";
import location from "../../assets/images/location2.svg";
import Email from "../../assets/images/Email.svg";
import linkdin from "../../assets/images/linkdin.svg"
import contactLogo from "../../assets/images/Myramedical1Contact.png";
import * as Yup from "yup";
import { motion } from "framer-motion";
import useAPI from "../../hooks/useApi";
import { error_alert, stringNotEmpty, success_alert } from "../../utils";
import { Link } from "react-router-dom";
import { BiLoaderAlt } from "react-icons/bi";
import { EMAIL_REGX } from "../../constants";

// Variants for each individual Stats component
const itemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// Variants for the parent container (Vision)
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

function ContactForm() {
  const Axios = useAPI();

  const initialValues={
    name: "",
    email: "",
    subject:"",
    message:""
  }

  Yup.addMethod(Yup.string, 'stringNotEmpty', stringNotEmpty)

  const validationSchema=Yup.object({
    name: Yup.string().stringNotEmpty(),
    subject: Yup.string().stringNotEmpty(),
    email: Yup.string().email("Please enter a valid email").matches(EMAIL_REGX, "Please enter a valid email").required("Required"),
    message: Yup.string().stringNotEmpty()
  })

  const handleSubmit = async (values, formikBag) => {
    console.log("ssdskjs")
    try {
      const response = await Axios.post("contact-us/", {
        name: values.name,
        subject: values.subject,
        email: values.email,
        message: values.message,
      });
      success_alert("Message has been sent");
      formikBag.resetForm();
    } catch (error) {
      error_alert("The message could not be sent");
      throw error;
    }
  }
  

  return (
    <div>
      <motion.div
        id="contact"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <div className="bg-clr-ternity pb-5 md:pb-8 lg:pb-10 xl:pb-12 px-3 xs:px-5 md:px-16 xl:px-32  3xl:px-72 imac:px-96">
          <div className="py-10 text-left">
            <motion.img
              variants={itemVariants}
              viewport={{ once: true, amount: 0.2 }}
              src={contactLogo}
              alt="Contact Logo"
              className="mx-auto md:mx-0"
            />
          </div>
          <div className="flex flex-col md:flex-row lg:gap-28 lg:justify-between">
            <div className="text-white font-Roboto_Flex flex flex-col gap-9 w-full lg:w-[50%]">
              <motion.h1
                variants={itemVariants}
                viewport={{ once: true, amount: 0.2 }}
                className="text-[1.4rem] md:text-[1.4rem] lg:text-[1.5rem] xl:text-[1.5rem] 3xl:text-[1.7rem] font-Roboto_Flex font-medium md:font-semibold lg:font-medium text-center md:text-left"
              >
                Contact Us
              </motion.h1>
              <div className="mx-auto md:mx-0 xl_sm:mx-0">
                <div className="flex flex-col space-y-9">
                  <div className="flex flex-col lg:space-y-9">
                    <div className="flex space-x-14">
                      <motion.div
                        variants={itemVariants}
                        viewport={{ once: true, amount: 0.2 }}
                        className="flex flex-col space-y-16"
                      >
                        <img
                          src={location}
                          alt="Location Icon"
                          className="w-auto h-[5rem] xs:h-[5rem] lg:h-[5rem] 3xl:h-[6rem] object-contain"
                        />
                        <img
                          src={Email}
                          alt="Email Icon"
                          className="w-auto h-[5rem] xs:h-[5rem] lg:h-[5rem] 3xl:h-[6rem] object-contain"
                        />
                      </motion.div>

                      <motion.div
                        variants={itemVariants}
                        viewport={{ once: true, amount: 0.2 }}
                        className="flex flex-col space-y-16 xl:space-y-[75px] 2xl:space-y-20 font-Roboto_Flex"
                      >
                        <div>
                          <h1 className="text-[1.2rem] 3xl:text-[1.5rem] font-bold">
                            Address:
                          </h1>
                          <p>
                            Route d'Yverdon 12,
                            <br />
                            1033 Cheseaux-sur-Lausanne (VD) - Switzerland
                          </p>
                        </div>
                        <div>
                          <h1 className="text-[1.2rem] 3xl:text-[1.5rem] font-bold">
                            Email:
                          </h1>
                          <a
                            href="mailto:info@myramedical.com"
                            className="block"
                          >
                            info@myramedical.com
                          </a>
                        </div>
                        <motion.p
                          variants={itemVariants}
                          viewport={{ once: true, amount: 0.2 }}
                          className="text-[1.2rem] 3xl:text-[1.5rem] mt-11 xl:mt-16 font-light font-default"
                        >
                          VAT: CHE-471.117.215
                        </motion.p>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-2xl contactForm w-full lg:w-[45%] mt-8 lg:mt-0">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
               {(formik) => (
                  <Form>
                    <motion.div
                      variants={containerVariants}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.2 }}
                      className="flex flex-col px-4 py-4 space-y-7"
                    >
                      <div className="grid grid-cols-1 lg:grid-cols-2 space-y-5 lg:space-y-0 lg:space-x-5">
                        <MyTextInput
                          label="Your Name"
                          name="name"
                          type="text"
                          placeholder="Name"
                        />
                        <MyTextInput
                          label="Your Email"
                          name="email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>

                      <MyTextInput
                        label="Subject"
                        name="subject"
                        type="text"
                        placeholder="Subject"
                      />
                      <MyTextArea
                        label="Your Message"
                        name="message"
                        type="message"
                        placeholder="Message"
                      />
                      <motion.button
                        variants={itemVariants}
                        viewport={{ once: true, amount: 0.2 }}
                        type="submit"
                        disabled={formik.isSubmitting}
                        className=" md:ml-auto disabled:cursor-not-allowed bg-clr-tertiary rounded-lg text-white text-[1.17rem]  md:text-[1.27rem] py-2 md:px-11 md:py-3 bg-[#FCB32C] hover:bg-[#D18800] font-default">
                        {
                          formik.isSubmitting?
                          <BiLoaderAlt className="mx-auto animate-spin text-xl text-white"/>
                          :
                          "Send Message"
                        }
                      </motion.button>
                    </motion.div>
                  </Form>
               )}
              </Formik>
            </div>
          </div>
        </div>
      </motion.div>
      <div className="flex justify-between px-3 xs:px-5 md:px-14 py-2 md:py-3 mt-2">
        <div className="flex flex-col lg:flex-row lg:space-x-9 text-[0.65rem] md:text-[0.85rem] bg-white text-[#0000007D]">
          <p>Copyright 2024 - Myra Medical | All Right Reserved</p>
          <p>
            {/* <Link to="/"> */}
              <span className="underline underline-offset-1 md:hover:text-black cursor-pointer">
                Privacy Policy
              </span>
            {/* </Link> */}
            {" "}
            |
            {" "}
            <Link to="/cookie-policy">
              <span className="underline underline-offset-1 md:hover:text-black cursor-pointer">
                Cookie Policy
              </span>
            </Link>
          </p>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/company/myra-medical-s%C3%A0rl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="lg_sm:hover:scale-110 ease-linear transition-all" src={linkdin} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;

const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const formikProps = useFormikContext();

  return (
    <motion.div
      variants={itemVariants}
      viewport={{ once: true, amount: 0.2 }}
      className="flex flex-col space-y-1 focus-within:text-clr-tertiary"
    >
      {/* Optional Label */}
      {/* <label className='text-start text-[0.8rem] font-semibold ' htmlFor={props.id || props.name}>{label}</label> */}

      <textarea
        className={`text-[0.9rem] md:text-[1.2rem] 3xl:text-[1.5rem]
                    py-4 px-3 min-h-64
                    rounded-lg focus:outline-none
                    placeholder:text-[0.9rem] md:placeholder:text-[1.2rem] 3xl:placeholder:text-[1.5rem]
                    text-clr-ternity placeholder:text-clr-ternity font-Roboto_Flex
                  `}
        {...field}
        {...props}
        placeholder={props.placeholder || "Messages"}
      />

      {/* Validation Error */}
      {meta.touched && meta.error ? (
        <p className="error text-start text-red-500 text-[0.9rem] pl-3">
          {meta.error}
        </p>
      ) : null}
    </motion.div>
  );
};

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const formikProps = useFormikContext();

  return (
    <motion.div
      variants={itemVariants}
      viewport={{ once: true, amount: 0.2 }}
      className={`  flex flex-col focus-within:text-clr-tertiary`}
    >
      {/* <label className='text-start text-[0.8rem] font-semibold' htmlFor={props.id || props.name}>{label}</label> */}
      <div className="flex flex-col space-y-2">
        <input
          className={`text-input h-10 lg:h-12 px-3
                      text-[0.9rem] md:text-[1.2rem] 3xl:text-[1.5rem]
                      focus:outline-none rounded-lg
                      placeholder:text-[0.9rem] md:placeholder:text-[1.2rem] 3xl:placeholder:text-[1.5rem]
                      text-clr-ternity placeholder:text-clr-ternity font-Roboto_Flex
                    `}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <p className="error text-start text-red-500 text-[0.9rem] pl-3">
            {meta.error}
          </p>
        ) : null}
      </div>
    </motion.div>
  );
};
