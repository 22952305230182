import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";

function SideBar({setActive, active, handleScrollTo}) {

    const menu = useRef(null);

    const tl = useRef(null);
    const activeRef = active;

    useEffect(() => {
      tl.current = gsap.timeline({
        paused: true,
      })
      tl.current.to(menu.current, {
        top: '3.75rem',
        ease: "power1.out",
        duration: 0.4,
      })
    }, [])

    useEffect(() => {
      activeRef ? tl.current.play() : tl.current.reverse()
    }, [activeRef])



    function handleSideBar(scrollToId){
        setActive(!active)
        handleScrollTo(scrollToId);
    }


  return (

        <div
        className={`fixed z-20 -top-[500vh] w-[100vw] bg-white py-10 px-5 shadow-[0_45px_100px_-15px_rgba(0,0,0,1)] md:hidden border-t-2 border-clr-fourth text-clr-primary overflow-y-scroll max-h-[100vh]`}
        ref={menu}>
            <ul className="text-[1.15rem] font-bold">
                <li className="cursor-pointer hover:under py-4 text-center" onClick={()=>handleSideBar("vision")} ><Link to="/"  >Vision</Link></li>
                <li className="cursor-pointer hover:under py-4 text-center" onClick={()=>handleSideBar("mission")} ><Link to="/" >Mission</Link></li>
                <li className="cursor-pointer hover:under py-4 text-center" onClick={()=>handleSideBar("management")} ><Link to="/" >Management</Link></li>
                <li className="cursor-pointer hover:under py-4 text-center" onClick={()=>handleSideBar("Expertise")} ><Link to="/" >Expertise</Link></li>
                <li className="cursor-pointer hover:under py-4 text-center" onClick={()=>handleSideBar("portfolio")} ><Link to="/" >Portfolio</Link></li>
                <li className="cursor-pointer hover:under py-4 text-center" onClick={()=>handleSideBar("TalentDevelopment")} ><Link to="/" >Talent Development</Link></li>
                <li className="cursor-pointer hover:under py-4 text-center" onClick={()=>handleSideBar("contact")} ><Link to="/" >Contact</Link></li>
            </ul>
        </div>
  )
}

export default SideBar