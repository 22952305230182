import React, { useEffect, useRef, useState } from "react";
import managment1 from "../../assets/images/managent1.png";
import managment2 from "../../assets/images/managent2.png";
import { FaPlus } from "react-icons/fa6";
import ManagmentBackGround from "../../assets/images/managmentbackground.png";
import { HiMiniXMark } from "react-icons/hi2";
import { AnimatePresence, motion } from "framer-motion";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { BiX } from "react-icons/bi";
import { BiPlus } from "react-icons/bi";


const ManagementDetail = [
  {
    id: 0,
    name: "Dr. Enrico Pasquino",
    discription: (
      <>
        <p>
          He has more than 30 years of experience in senior positions within
          Multinational Organizations in the biomedical sector, including Sorin
          Group, CID Vascular (now Alvimedica), and Edwards Lifesciences Europe.
        </p>
        <p>
          He has served as VP of R&D Preclinical and Clinical Affairs,
          specializing in mechanical and biological surgical and sutureless
          heart valve prostheses, transcatheter valve prostheses, annuloplasty
          rings for mitral valve repair and drug-eluting coronary stents.
          <br />
          In 2014, he established his Swiss-based Consultancy Company, Myra
          Medical sàrl, and other innovative Startups focused on developing
          implantable cardiovascular devices with new concepts.
        </p>
        <p>
          Specifically, he Co-Invented and Co-Founded Epygon Italie srl (now in
          Affluent Medical Group) to develop a transcatheter mitral
          bioprosthetic valve. Currently, he serves as a Consulting CTO of
          AorticLab Srl, which he also Co-Invented and Co-Founded. As the author
          of about 30 Patents, he contributes as an Advisor to many innovative
          Projects, leveraging his extensive experience in R&D, Regulatory and
          Clinical Affairs.
        </p>
      </>
    ),
  },
  {
    id: 1,
    name: "Eng. Stefano Pasquino",
    discription: (
      <>
        <p>
          Stefano Pasquino brings ten years of experience in the biomedical
          field, engaging in various roles from operations to finance and
          administration.
        </p>
        <p>
          In 2014, he transitioned to a project manager role at the startup{" "}
          <b>Epygon</b> sas, which aimed to develop a transcatheter mitral
          bioprosthetic valve. His responsibilities expanded in 2016 when he
          became the Sole Administrator of Epygon's subsidiary in Italy,{" "}
          <b>Epygon Italie srl</b>, where he oversaw daily operations and
          managed administrative and financial tasks. After{" "}
          <b>Affluent Medical SA</b> acquired the <b>Epygon</b> project in 2018,
          he took on operationalv and financial roles, ensuring a smooth
          technology transfer to Chinese subsidiary.
        </p>
        <p>
          Stefano is also a co-founder of <b>Myra Medical sàrl</b>, a
          consultancy firm that aids biomedical startups, particularly in
          oncology and cardiovascular areas, providing expertise in product
          design, preclinical testing, and regulatory and clinical strategies.
          Since December 2022, he has co-founded and served as CEO of{" "}
          <b>Tresquare Technologies</b>, an Italian biomedical company dedicated
          to developing transcatheter medical devices for aortic valve stenosis
          treatment. In this position, he guides the company’s strategic
          initiatives while focusing on enhancing patient care through
          innovative devices and maintaining compliance with international
          regulations.
        </p>
      </>
    ),
  },
];

// Variants for each individual Stats component
const itemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// Variants for the parent container (Vision)
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

function Managment() {
  const [active, setActive] = useState();
  const detailRef = useRef(null);

  function scrollToSmoothly(pos, time) {
    var currentPos = window.pageYOffset;
    var start = null;
    if (time == null) time = 500;
    pos = pos + 1;
    time = time + 1;
    window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start;
      var progress = currentTime - start;
      if (currentPos < pos) {
        window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
      } else {
        window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
      }
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    });
  }

  useEffect(() => {
    if (active && detailRef.current) {
      // detailRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });     //didn't worked on ios

      const element = document.getElementById("detail");
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = window.scrollY + elementPosition;

      scrollToSmoothly(offsetPosition, 500);
    }
  }, [active]);
  return (
    <div className="flex flex-col space-y-10 3xl:space-y-7                                 px-3 xs:px-5 md:px-16 xl:px-24 3xl:px-52">
      <motion.div
        id="management"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        className="flex flex-col md:flex-row items-center lg:items-stretch justify-center lg:justify-between space-y-4 md:space-y-0 md:space-x-7 lg:space-x-8 xl:space-x-12"
      >
        <Cards
          img={managment1}
          name={"Dr. Enrico Pasquino"}
          id={0}
          setActive={setActive}
          active={active}
          designation={"CEO Co-Founder"}
        />

        <div className="md:hidden">
          <AnimatePresence mode="wait">
            {active?.id === 0 ? (
              <ManagementDetailFun key={active} active={active} />
            ) : null}
          </AnimatePresence>
        </div>

        <Cards
          img={managment2}
          name={"Eng. Stefano Pasquino"}
          id={1}
          setActive={setActive}
          active={active}
          designation={"Director Business Development"}
        />

        <div className="md:hidden">
          <AnimatePresence mode="wait">
            {active?.id === 1 ? (
              <ManagementDetailFun key={active} active={active} />
            ) : null}
          </AnimatePresence>
        </div>
      </motion.div>

      <div id="detail" className="hidden md:block" ref={detailRef}>
        <AnimatePresence mode="wait" ref={detailRef}>
          {active ? <ManagementDetailFun key={active} active={active} /> : null}
        </AnimatePresence>
      </div>
      <Expert />
    </div>
  );
}

function Cards(props) {
  function handleDetail(id) {
    if (props.active && props.active.id === id) {
      props.setActive(null);
    } else {
      props.setActive(ManagementDetail.find((item) => item.id === id));
    }
  }

  return (
    <motion.div
      variants={itemVariants}
      viewport={{ once: true, amount: 0.2 }}
      className="w-full md_sm:w-[55%] lg:w-[60%] rounded-3xl overflow-hidden py-10 md:py-10 lg:py-20 flex flex-col items-center justify-center space-y-7 bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${ManagmentBackGround})` }}
    >
      {/* Image Section */}
      <div className="relative w-[40%]">
        <img
          src={props.img}
          alt="Profile"
          className="w-full h-full rounded-full object-cover"
        />
        {/* Plus Icon */}
        <div
          className={`absolute -bottom-4 md:-bottom-5 lg_sm:-bottom-5 right-1/2 translate-x-1/2
                      text-[1.5rem] md:text-[2rem] xl:text-[2.7rem] flex items-center justify-center rounded-full w-[28px] h-[28px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] xl:h-[55px] xl:w-[55px] ${
                        props.active && props.active.id === props.id
                          ? "bg-white text-clr-primary lg_sm:hover:bg-clr-primary lg_sm:hover:text-white border-2 border-clr-primary "
                          : "bg-clr-primary text-white lg_sm:hover:text-clr-primary lg_sm:hover:bg-white lg_sm:hover:border-2 lg_sm:border-clr-primary"
                      } cursor-pointer rounded-full flex items-center justify-center shadow-md`}
          onClick={() => handleDetail(props.id)}
        >
          <span
            className={`text-[1.5rem] md:text-[2rem] xl:text-[2.7rem] ${
              props.active && props.active.id === props.id
                ? " font-black"
                : " font-bold"
            } `}
          >
            {props.active && props.active.id === props.id ? (
              // <HiMiniXMark />
              <BiX />
            ) : (
              // <FaPlus />
              <BiPlus />

            )}
          </span>
        </div>
      </div>

      {/* Name and Designation */}
      <div className="text-center text-white space-y-2 md:space-y-4 font-barlow">
        <h3 className=" font-semibold text-[1.1rem] md:text-[1.5rem] lg:text-[2rem] xl:text-[2.25rem] 3xl:text-[3.2rem]">
          {props.name}
        </h3>
        <p className="text-sm text-[1rem] md:text-[1.3rem] lg:text-[1.5rem] xl:text-[2.2rem] 3xl:text[2.2rem]">
          {props.designation}
        </p>
      </div>
    </motion.div>
  );
}

function ManagementDetailFun(props) {
  return (
    <motion.div
      initial={{
        height: 0,
      }}
      exit={{
        height: 0,
      }}
      animate={{
        height: "auto",
      }}
      transition={{
        duration: 0.5,
      }}
      className="overflow-hidden"
    >
      <div
        id="managementDetail"
        className={`text-clr-primary space-y-4 md:space-y-5 lg:space-y-6 overflow-hidden transition-all duration-700 ease-in-out transform px-5`}
        style={{
          maxHeight: props.active ? "100000px" : "0",
          opacity: props.active ? 1 : 0,
          paddingTop: props.active ? "1rem" : "0",
          paddingBottom: props.active ? "1rem" : "0",
        }}
      >
        <h1 className="text-[1.4rem] xs:text-[1.7rem] md:text-[2.25rem] lg:text-[2.3rem] xl:text-[2.5rem] 3xl:text-[2.8rem] font-se md:font-bold">
          {props.active.name}
        </h1>
        <div className="space-y-5 text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem]">
          {props.active.discription}
        </div>
      </div>
    </motion.div>
  );
}

function Expert() {
  return (
    <motion.div
      id="Expertise"
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      className="text-clr-primary space-y-3 md:space-y-5 lg:space-y-7 pb-10 "
    >
      <motion.h1
        variants={itemVariants}
        viewport={{ once: true, amount: 0.2 }}
        className="text-center font-black mb-4 text-[2rem] xs:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] 3xl:text-[4.3rem] text-clr-primary font-default"
      >
        Expertise
      </motion.h1>
      <motion.div
        variants={itemVariants}
        viewport={{ once: true, amount: 0.2 }}
        className="text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem] space-y- font-barlow leading-snug"
      >
        <p>
          If you are a startup or a company needing specialized and technical
          support for the initial development and certification of your medical
          device, we can assist you. Our expertise covers the entire process,
          from early prototyping (clean room ISO 7 available for pre-clinical
          and clinical phase device assembling) to certification with CE Mark or
          FDA approval, ensuring you have the guidance and resources to bring
          your innovation to market.
        </p>
        <p>
          Below, you can explore the services we offer to support your journey.
        </p>
      </motion.div>
    </motion.div>
  );
}

export default Managment;
