import { FormattedMessage } from "react-intl";
import { BsFiletypeDocx, BsFiletypeDoc, BsFiletypePdf, BsFileExcel, BsFiletypeXml } from "react-icons/bs";

// export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_URL = "https://myra-medical-api.astutesoftwares00.com/"
// export const BASE_URL = "https://29a5-101-53-238-71.ngrok-free.app/"

// export const BASE_URL = "http://192.168.10.7:8000/" // Local
// export const BASE_URL = "https://diasorin-dev-api.astutesoftwares00.com/" // Development

export const DATE_FORMAT_SERVER = "yyyy-MM-dd";
export const DATE_FORMAT_DISPLAY = "dd/MM/yyyy";
export const DATETIME_FORMAT_DISPLAY = "dd/MM/yyyy HH:mm";
export const ACCESS_TOKEN = "kaffeina25_access";


export const ROLE_ADMIN = {
    value: "super-admin",
    access_level: 900,
    label: <FormattedMessage id="super_admin" defaultMessage={"Super Admin"} />,
}
export const ROLE_WAITER = {
    value: "waitress",
    label: "Waiter",
}
export const ROLE_WORKER = {
    value: "worker",
    access_level: 400,
    label: <FormattedMessage id="worker" defaultMessage={"Worker"} />,
}
export const ROLE_MANAGER = {
    value: "manager",
    access_level: 500,
    label: <FormattedMessage id="manager" defaultMessage={"Manager"} />,
}
export const ROLE_DEPARTMENT_MANGER = {
    value: "department-manager",
    access_level: 600,
    label: <FormattedMessage id="department_manager" defaultMessage={"Department Manager"} />,
}
export const ROLE_SUPPLIER_MANAGER = {
    value: "supplier-manager",
    access_level: 700,
    label: <FormattedMessage id="supplier_manager" defaultMessage={"Supplier Manager"} />,
}
export const ROLE_KEEPER = {
    value: "keeper",
    access_level: 800,
    label: <FormattedMessage id="keeper" defaultMessage={"Keeper"} />,
}

export const ROLES = [
    ROLE_WORKER,
    ROLE_MANAGER,
    ROLE_DEPARTMENT_MANGER,
    ROLE_SUPPLIER_MANAGER,
    ROLE_KEEPER,
    ROLE_ADMIN
]
export const ROLES_ARR = [
    ROLE_WORKER,
    ROLE_MANAGER,
    ROLE_DEPARTMENT_MANGER,
    ROLE_SUPPLIER_MANAGER,
    ROLE_KEEPER,
]
export const APPROVER = [
    {
        value: "true",
        access_level: 800,
        label: <FormattedMessage id="yes" defaultMessage={"Yes"} />,
    },
    {
        value: "false",
        access_level: 800,
        label: <FormattedMessage id="no" defaultMessage={"No"} />,
    }
]



export const APP_VERSION = "v0.0.1"

export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";
export const POPUP_TYPE_SIDE = "side";
export const POPUP_TYPE_DEFAULT = "default";

export const initialPopupState = {
    size: POPUP_MEDIUM,
    heading: "",
    hideDefaultClose: false,
    contained: true,
    component: null,
    type: "default"
};

export const LANGUAGE_ACCESS = "qc_checker_lang"
export const EN = "en"
export const IT = "it"

export const OPTION_YES = {
    value: true,
    label: <FormattedMessage id="yes" defaultMessage={"Yes"} />,
    label: "Yes"
}
export const OPTION_NO = {
    value: false,
    label: <FormattedMessage id="no" defaultMessage={"No"} />,
}

export const OPTIONS_BOOL = [
    OPTION_YES,
    OPTION_NO
]

export const ASSENDING_ORDER = "asc"
export const DESENDING_ORDER = "desc"
export const ORDER_DESCENDING = {
    value: "desc",
    label: <FormattedMessage id="most_recent" defaultMessage={"Most Recent"} />,
}
export const ORDER_ASCENDING = {
    value: "asc",
    label: <FormattedMessage id="least_recent" defaultMessage={"Least Recent"} />,
}

export const SORT_ORDERS = [
    ORDER_DESCENDING,
    ORDER_ASCENDING
]
export const LISTING_LIMIT = 30;


export const USER_ACTIVE = {
    value: "active",
    label: <FormattedMessage id="active" defaultMessage={"Active"} />,
}
export const USER_PENDING = {
    value: "pending",
    label: <FormattedMessage id="pending" defaultMessage={"Pending"} />,
}
export const USER_DISABLED = {
    value: "disable",
    label: <FormattedMessage id="disabled" defaultMessage={"Disabled"} />,
}

export const USER_STATUS = [
    USER_ACTIVE,
    USER_PENDING,
    USER_DISABLED
]

export const ACTIVE = {
    value: "true",
    label: <FormattedMessage id="active" defaultMessage={"Active"} />,
}
export const DISABLED = {
    value: "false",
    label: <FormattedMessage id="disabled" defaultMessage={"Disabled"} />,
}

export const IS_ACTIVE = [
    ACTIVE,
    DISABLED
]

export const APP_LANGUAGE = "diasorin_lang";

export const LANGUAGE_OPTIONS = [
    {
        value: {
            code: "en",
            language: "English"
        },
        label: <>
            <div className="flex items-center justify-start gap-2">
                <img src="/images/flags/us.png" alt="English" className="h-6 w-6" />
                <p className="font-semibold">- EN</p>
            </div></>
    },
    // {
    //     value: {
    //         code: "it",
    //         language: "Italian",
    //     },
    //     label: <>
    //         <div className="flex items-center justify-start gap-2">
    //             <img src="/images/flags/it.png" alt="Italian" className="h-6 w-6" />
    //             <p className="font-semibold">- IT</p>
    //         </div>
    //     </>
    // },
    // {
    //     value: {
    //         code: "zh-hans",
    //         language: "简体中文"
    //     },
    //     label: <>
    //         <div className="flex items-center justify-start gap-2">
    //             <img src="/images/flags/cn.png" alt="Chinese" className="h-6 w-6" />
    //             <p className="font-semibold">- CN</p>
    //         </div>
    //     </>
    // },
]

export const TICKETS_VIEW_NOT_SELECTED = "not-selected"

export const TICKET_STATUS_SUBMITTED = {
    label: "Submitted",
    value: "submitted",
    descriptionAction: "Submit the ticket and send to manager for review.",
    description: "Ticket has been submitted by the laboratory worker and is awaiting review.",
    backgroundColor: "bg-green-100",
    textColor: "text-green-500",
    borderActive: "border-green-500 bg-green-50 shadow-bottom",
    borderNormal: "border-base hover:border-green-500",
    iconColor: "text-green-500",
    svgColor: "#22c55e"
}
export const TICKET_STATUS_MANAGER_REVIEW = {
    label: "Manager Review",
    value: "manager-review",
    description: "Ticket is under review by the immediate manager.",
    descriptionAction: "Approve the ticket details and send the ticket to the manager for review.",
    backgroundColor: "bg-violet-100",
    textColor: "text-violet-500",
    borderActive: "border-violet-500 bg-violet-50 shadow-bottom",
    borderNormal: "border-base hover:border-violet-500",
    iconColor: "text-violet-500",
    svgColor: "#8b5cf6"

}
export const TICKET_STATUS_RETURNED = {
    label: "Returned",
    value: "returned",
    descriptionAction: "Send the ticket back to require more information.",
    description: "Ticket is sent back to the laboratory worker for additional details or justification.",
    backgroundColor: "bg-amber-100",
    textColor: "text-amber-500",
    borderActive: "border-amber-500 bg-amber-50 shadow-bottom",
    borderNormal: "border-base hover:border-amber-500",
    iconColor: "text-amber-500",
    svgColor: "#f59e0b",
}
export const TICKET_STATUS_REJECTED = {
    label: "Rejected",
    value: "rejected",
    descriptionAction: "Reject and close the ticket. No further processing will occur.",
    description: "Ticket has been rejected by a manager at any level, and no further processing will occur.",
    backgroundColor: "bg-red-100",
    textColor: "text-red-500",
    borderActive: "border-red-500 bg-red-50 shadow-bottom",
    borderNormal: "border-base hover:border-red-500",
    iconColor: "text-red-500",
    svgColor: "#ef4444"

}
export const TICKET_STATUS_DEPARTMENT_REVIEW = {
    label: "Department Review",
    value: "department-review",
    descriptionAction: "Approve the ticket details and send it to the department manager for review.",
    description: "Ticket is under review by the department or higher-level manager.",
    backgroundColor: "bg-fuchsia-100",
    textColor: "text-fuchsia-500",
    borderActive: "border-fuchsia-500 bg-fuchsia-50 shadow-bottom",
    borderNormal: "border-base hover:border-fuchsia-500",
    iconColor: "text-fuchsia-500",
    svgColor: "#d946ef",

}
export const TICKET_STATUS_APPROVED = {
    label: "Approved",
    value: "approved",
    description: "Ticket has been approved by all required managers.",
    descriptionAction: "Approve that the ticket has been reviewed and verified. Mark it as approved so the supplier can proceed it further.",
    backgroundColor: "bg-emerald-100",
    textColor: "text-emerald-500",
    borderActive: "border-emerald-500 bg-emerald-50 shadow-bottom",
    borderNormal: "border-base hover:border-emerald-500",
    iconColor: "text-emerald-500",
    svgColor: "#10b981"

}
export const TICKET_STATUS_SENT_TO_SUPPLIER = {
    label: "Sent to Supplier",
    value: "sent-to-supplier",
    description: "Ticket has been manually forwarded to the supplier for order processing.",
    backgroundColor: "bg-rose-100",
    textColor: "text-rose-500",
    borderActive: "border-rose-700 bg-rose-50 shadow-bottom",
    borderNormal: "border-base hover:border-rose-700",
    iconColor: "text-rose-500",
    svgColor: "#f43f5e"

}
export const TICKET_STATUS_IN_TRANSIT = {
    label: "In Transit",
    value: "in-transit",
    description: "Supplies are being shipped from the supplier to the warehouse (optional if tracking is available).",
    backgroundColor: "bg-teal-100",
    textColor: "text-teal-500",
    borderActive: "border-teal-500 bg-teal-50 shadow-bottom",
    borderNormal: "border-base hover:border-teal-500",
    iconColor: "text-teal-500",
    svgColor: "#14b8a6",

}
export const TICKET_STATUS_DELIVERED_TO_WAREHOUSE = {
    label: "Delivered to Warehouse",
    value: "delivered-to-warehouse",
    description: "Supplies have arrived at the warehouse, and the ticket is updated with storage details.",
    backgroundColor: "bg-lime-100",
    textColor: "text-lime-500",
    borderActive: "border-lime-500 bg-lime-50 shadow-bottom",
    borderNormal: "border-base hover:border-lime-500",
    iconColor: "text-lime-500",
    svgColor: "#84cc16",
}

export const TICKET_STATUSES = [
    TICKET_STATUS_SUBMITTED,
    TICKET_STATUS_MANAGER_REVIEW,
    TICKET_STATUS_RETURNED,
    TICKET_STATUS_REJECTED,
    TICKET_STATUS_DEPARTMENT_REVIEW,
    TICKET_STATUS_APPROVED,
    TICKET_STATUS_SENT_TO_SUPPLIER,
    TICKET_STATUS_IN_TRANSIT,
    TICKET_STATUS_DELIVERED_TO_WAREHOUSE,
]

export const FILE_PDF = "PDF"
export const FILE_XML = "XML"
export const FILE_IMAGE = "IMAGE"
export const FILE_EXCEL = "EXCEL";
export const FILE_WORD = "WORD";

export const SUPPORTED_FILE_FORMATS = [
    { extension: 'pdf', value: FILE_PDF, Icon: BsFiletypePdf },
    { extension: 'xml', value: FILE_XML, Icon: BsFiletypeXml },
    { extension: 'png', value: FILE_IMAGE },
    { extension: 'jpg', value: FILE_IMAGE },
    { extension: 'jpeg', value: FILE_IMAGE }, // Support both .jpg and .jpeg
    { extension: 'xls', value: FILE_EXCEL, Icon: BsFileExcel },  // Excel format
    { extension: 'xlsx', value: FILE_EXCEL, Icon: BsFileExcel }, // Excel format
    { extension: 'doc', value: FILE_WORD, Icon: BsFiletypeDoc },   // Word format
    { extension: 'docx', value: FILE_WORD, Icon: BsFiletypeDocx }  // Word format
];

export const SMOOTH_SPRING_TRANS = {
    type: "spring",
    damping: 20,
    stiffness: 70
}

export const GENERIC_ANIM = {
    initial: {
        opacity: 0,
        y: 50
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            ...SMOOTH_SPRING_TRANS
        }
    },
    exit: {
        opacity: 0,
        y: 50
    },

}

export const EMAIL_REGX = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
