import React from "react";
import missionpic from "../../assets/images/mission.png";
import { motion } from "framer-motion";

function Mission() {
  // Variants for each individual Stats component
  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  // Variants for the parent container (Vision)
  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <motion.div
      id="mission"
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      className="py-4 lg_sm:py-8 mb-10 xl:mb-0                       px-3 xs:px-5 md:px-16 xl:px-24 3xl:px-52"
    >
      <div className="flex flex-col lg_sm:flex-row items-center justify-between px-5 xl:px-10">
        <motion.div
          variants={itemVariants}
          viewport={{ once: true, amount: 0.2 }}
          className="w-fill lg:w-[60%] flex"
        >
          <img
            src={missionpic}
            alt="Mission"
            className="w-full h-auto ml-auto"
          />
        </motion.div>

        {/* Right Side - Text */}
        <motion.div
          variants={itemVariants}
          viewport={{ once: true, amount: 0.2 }}
          className="w-full lg:w-[40%] mt-6 lg:mt-0 text-right font-default"
        >
          <h1 className="font-black mb-4 text-[2rem] xs:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] 3xl:text-[4.3rem] text-clr-primary">
            Mission
          </h1>
          <p className="text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.37rem] 3xl:text-[1.5rem]  leading-relaxed">
            At <b>Myra Medical</b>, our mission is to advance healthcare with
            pioneering transcatheter interventional technologies for
            cardiovascular and oncology treatments, supporting startups with
            comprehensive R&D, Patenting, and Regulatory strategies. We aim to
            bring life-saving innovations to market, improving patient care and
            outcomes worldwide
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Mission;
