import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom';
import BaseRoutes from "./routes";
import { useSelector } from 'react-redux';
import en from "./languages/en.json";
import it from "./languages/it.json";
import cn from "./languages/cn.json";
import { IntlProvider } from 'react-intl';
import BasePopup from "./components/BasePopup"

// notifications alerts
import PopupProvider from './hooks/usePopup';
import { Toaster } from 'sonner'
// notifications alerts

const App = () => {
  const lang = useSelector((state) => state.language);

  return (
    <>
      {/* <IntlProvider messages={(lang == "zh-hans") ? cn : (lang == "it") ? it : (lang == "en") ? en : en} locale="en" defaultLocale="en"> */}
      <IntlProvider messages={it} locale="en" defaultLocale="en">
        <BrowserRouter>
          <PopupProvider> {/* -> BASE POPUP PROVIDER */}
            <Toaster richColors/>
            <BaseRoutes /> {/* -> ENTRY POINT FOR URLS */}
            <BasePopup /> {/* -> Base popup */}
          </PopupProvider>
        </BrowserRouter>
      </IntlProvider>
    </>
  )
}

export default App