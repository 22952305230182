import React from 'react'
import heroPic from '../../assets/images/hero3.jpg'
import { motion } from "framer-motion"

function HeroPage({scrollToId}) {
  return (
    <>
      <div
        id="about"
        ref={scrollToId}
        className="bg-clr-primary lg:flex min-h-[calc(100vh-70px)] md:min-h-[calc(100vh-70px)] 3xl:min-h-[calc(100vh-120px)] text-white
                    px-3 xs:px-5 md:px-12 lg:px-14 2k:px-28                    py-10 xs:py-12 lg:py-0 text-left md:gap-5 lg:gap-10"
        style={{backgroundImage: `url(${heroPic})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",}}
      >
        <div className="lg:w-[90%] 3xl:w-[80%]  w-[85%] md:pt-[3%] lg:pt-[5%]">
          <motion.p
          initial={{
            opacity: 0,
            y:50,
          }}
          whileInView={{
            opacity: 1,
            y:0
          }}
          transition={{
            duration: 1,
          }}
          viewport={{
            once:true,
            amount:0.2
          }}
          className=" intro_content heading font-semibold lg:font-bold
                      text-[3.2rem] xs:text-[3.5rem] sm:text-[4.1rem] md_sm:text-[4.2rem] md:text-[4.5rem] lg:text-[4.6rem] xl:text-[5.2rem] xl_lg:text-[5.5rem] 3xl:text-[6.5rem]  2k:text-[7.5rem] imac:text-[8.5rem]
                      mt-[20%] xs:mt-0 lg:pl-0
                      leading-snug lg:leading-none 3xl:leading-snug


                      translate-x-14 transform
                      ">
              Fueling medtech <br /> innovation, from vision <br /> to medical device <br />
          </motion.p>
        </div>
      </div>
    </>
  )
}

export default HeroPage