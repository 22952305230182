import { ACCESS_TOKEN, APP_LANGUAGE, EN, IT, LANGUAGE_ACCESS, LANGUAGE_OPTIONS, SUPPORTED_FILE_FORMATS } from "./constants";
import { toast } from "sonner";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import LANG_IT from 'date-fns/locale/it';
import LANG_EN_US from 'date-fns/locale/en-US';
import { CiFileOn } from "react-icons/ci";

export const getAuthUser = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).user;
    } else {
        return false;
    }
};

export const getToken = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).access_token;
    } else {
        return false;
    }
};

export const setToken = (access_token) => {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(access_token));
};

export const removeToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
};

export const success_alert = (message, title) => {
    if(title){
        toast.success(title, {
            description: message,
        })
    } else{
        toast.success(message)
    }
};

export const error_alert = (message, title) => {
    if(title){
        toast.error(title, {
            description: message,
        })
    } else{
        toast.error(message)
    }
};

export function customStringify(input) {
    // Check if the input is already a string
    if (typeof input === 'string') {
        return input;
    }

    // Use JSON.stringify to convert the object to a JSON string
    let jsonString = JSON.stringify(input, (key, value) => {
        // Check if the value is a string
        if (typeof value === 'string') {
            // Remove quotes around the string
            return value;
        }
        return value;
    });

    return jsonString;
}

export function stringNotEmpty() {
    return yup.mixed().test({
        name: "stringNotEmpty",
        exclusive: false,
        // message: <FormattedMessage id="required" defaultMessage="Required" />,
        message: "Required",
        test: function (value) {
            if (value !== undefined && value !== false) {
                return value.trim() !== "";
            } else if (value === undefined) {
                return false;
            }
        },
    });
}

export const selectStylesFilter = (error, hasValue) => ({
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: "0.875rem",
        color: "#9ca3af"
    }),
    control: (provided, state) => ({
        ...provided,
        // backgroundColor: state.isDisabled ? "#fafafa" : "#f6fbff",
        borderRadius: "6px",
        minHeight: "40px",

        border: state.isDisabled
            ? "2px solid #d1d5db"
            : state.isFocused
                ? "2px solid #000"
                : state.isSelected
                    ? "2px solid #000"
                    : state.hasValue || hasValue
                        ? "2px solid #000"
                        : error
                            ? "2px solid #ef4444"
                            : "2px solid #E5E7EB",
        boxShadow: state.isFocused ? "0px 0px 0px #000000" : "none",
        "&:hover": {
            //   border: "2px solid #E5E7EB",
            boxShadow: "0px 0px 0px #000000",
        },
        fontSize: "0.875rem",
    }),

    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "#000 !important" : "#FFFFFF",
        color: state.isSelected ? "#FFFFFF" : "#000 !important",
        "&:hover": {
            background: "#000",
            color: "#ffffff !important",
        },
        fontSize: "0.875rem",
    }),
}
)

export const selectStylesField = (error, hasValue) => ({
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: "0.875rem",
        color: "#9ca3af"
    }),
    control: (provided, state) => ({
        ...provided,
        // backgroundColor: state.isDisabled ? "#fafafa" : "#f6fbff",
        borderRadius: "10px",
        height: "50px",

        border: state.isDisabled
            ? "2px solid #d1d5db"
            : state.isFocused
                ? "2px solid #000"
                : state.isSelected
                    ? "2px solid #000"
                    : state.hasValue || hasValue
                        ? "2px solid #000"
                        : error
                            ? "2px solid #ef4444"
                            : "2px solid #E5E7EB",
        boxShadow: state.isFocused ? "0px 0px 0px #000000" : "none",
        "&:hover": {
            //   border: "2px solid #E5E7EB",
            boxShadow: "0px 0px 0px #000000",
        },
        fontSize: "0.875rem",
    }),

    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "#000 !important" : "#FFFFFF",
        color: state.isSelected ? "#FFFFFF" : "#000 !important",
        "&:hover": {
            background: "#000",
            color: "#ffffff !important",
        },
        fontSize: "0.875rem",
    }),
}
)

export const customTableStyles = {
    table: {
		style: {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            overflow: 'hidden', // Ensure the rounded corners are clipped
            boxShadow: "0 10px 20px 0 rgba(0,0,0,0.1)"
		},
	},
    tableWrapper: {
		style: {
		},
	},
    cells: {
		style: {
		},
		draggingStyle: {
            cursor: "pointer"
        },
	},
    rows: {
        style: {
            minHeight: '60px', // override the row height
            borderBottom: "0px solid #e2e8f0 !important",
            "&:last-child": {
                borderBottom: "none", // Removes bottom border for cells in the last row
            }
        }
    },
    headRow: {
        style: {
            width: "100%",
            borderBottom: "0",
            borderRadius: "20px",
        }
    },
    headCells: {
        style: {
            backgroundColor: "#06255B",
            // borderBottom: "0px solid red",
            color: "#fff",
            fontSize: "13px",
            textTransform: "uppercase",
            // backgroundColor: "#40e0d0",
            fontWeight: "600",
            borderRight: "2px solid #062760"
        }
    },
    cells: {
        style: {
            width: "100%",
            backgroundColor: "#fff",
            color: "#374151",
            fontWeight: "600",
            borderRight: "2px solid #dee2e7",
            borderBottom: "2px solid #dee2e7",
            padding: "5px 10px",
            "&> div:first-child": {
                width: "100%"
            },
            "&:last-child": {
                // borderBottom: "none", // Removes bottom border for cells in the last row
            }
            
        },
    },
    pagination: {
        style: {
            // backgroundColor: "#000",
            borderBottomRightRadius: "6px",
            borderBottomLeftRadius: "6px",
            // color: "#fff",
        },
        pageButtonsStyle: {
            color: "#fff !important",
            fill: "#fff !important",
            '&:disabled': {
				cursor: 'unset',
				color: "#4d4d4d !important",
				fill: "#4d4d4d !important",
			},
			'&:hover:not(:disabled)': {
				backgroundColor: "#fff !important",
			},
			'&:focus': {
				outline: 'none',
				backgroundColor: "#fff !important",
			},
        }
    }
};

export const setSelectedLanguage = (data) => {
    localStorage.setItem(LANGUAGE_ACCESS, JSON.stringify(data));
}
export const getSelectedLanguage = () => {
    let obj = localStorage.getItem(LANGUAGE_ACCESS);
    if (obj) {
        return JSON.parse(obj);
    } else {
        return {
            type: EN
        }
    }
}

export const calanderLocale = () => {
    let x = getSelectedLanguage()
    if (x) {
        if (x.type === IT) {
            return LANG_IT
        } else {
            return LANG_EN_US
        }

    } else {
        return LANG_EN_US
    }

}

export const logger = (msg) => {
    if (process.env.NODE_ENV === "development") {
        console.log(msg)
    } else {

    }
}

// export const supplierOptionsGenerator = (item) => ({value: item, label: `${item.social_reason_1} ${item.social_reason_2 ? item.social_reason_2 : ""}`})
export const supplierOptionsGenerator = (item) => ({ value: item, label: `${item.code} - ${item.social_reason_1} ${item.social_reason_2 ? item.social_reason_2 : ""}` })
export const productionStepOptionsGenerator = (item) => ({ value: item, label: item.label })
export const userOptionsGenerator = (item) => ({ value: item, label: <><div className="">{item.name + " " + item.sur_name}</div><div className="italic">{item.email}</div></> })
export const categoryOptionsGenerator = (item) => ({ value: item, label: `${item.code} - ${item.description}` })
export const defectAreaOptionsGenerator = (item) => ({ value: item, label: item.name })

export const userSearchSelect = (option, inputValue) => {
    const { name, sur_name, email } = option.value;

    // Convert the inputValue to lowercase to ensure case-insensitive matching
    const lowerInput = inputValue.toLowerCase();

    // Check if the input matches any of the relevant fields
    return (
        name.toLowerCase().includes(lowerInput) ||
        sur_name.toLowerCase().includes(lowerInput) ||
        email.toLowerCase().includes(lowerInput)
    );
};


export function insertPDFNewlines(str, index) {
    let result = '';
    let count = 0;

    for (let i = 0; i < str.length; i++) {
        result += str[i];

        if (str[i] === ' ') {
            count = 0; // Reset count when a space is encountered
            continue;
        }

        count++;

        if (count === index) {
            result += '\n';
            count = 0;
        }
    }

    return result;
}


export const getSeprateArrayOntheBasisOfColor = (data) => {
    const groupedItems = {};
    const uniqueItems = [];
    const repetitiveGroups = [];

    // Group items by color
    data.forEach(item => {
        if (!groupedItems[item.color]) {
            groupedItems[item.color] = [];
        }
        groupedItems[item.color].push(item);
    });

    // Separate into unique items and groups of repetitive items
    Object.values(groupedItems).forEach(group => {
        if (group.length === 1) {
            uniqueItems.push(group[0]); // Only one item, add to uniqueItems
        } else {
            repetitiveGroups.push(group); // Multiple items, add as a group to repetitiveGroups
        }
    });

    return [uniqueItems, repetitiveGroups]
}


export const getAppLanguage = () => {
    let lang = localStorage.getItem(APP_LANGUAGE);
    if (lang) {
        const matchedLanguage = LANGUAGE_OPTIONS.find(option => {
            return option.value.code === JSON.parse(lang)
        });
        if(matchedLanguage){
            return matchedLanguage.value.code
        } else{
            setAppLanguage(LANGUAGE_OPTIONS[0].value.code)
            return LANGUAGE_OPTIONS[0].value.code
        }
    } else {
        setAppLanguage(LANGUAGE_OPTIONS[0].value.code)
        return LANGUAGE_OPTIONS[0].value.code;
    }
};

export const setAppLanguage = (lang) => {
    localStorage.setItem(APP_LANGUAGE, JSON.stringify(lang));
};

export const selectLanguageStyles = (error, hasValue) => ({
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: "0.875rem",
        color: "#9ca3af"
    }),
    control: (provided, state) => ({
        ...provided,
        // backgroundColor: state.isDisabled ? "#fafafa" : "#f6fbff",
        borderRadius: "10px",
        minHeight: "40px",
        border: state.isDisabled
            ? "2px solid #d1d5db"
            : state.isFocused
                ? "2px solid #000"
                : state.isSelected
                    ? "2px solid #E5E7EB"
                    : state.hasValue || hasValue
                        ? "2px solid #E5E7EB"
                        : error
                            ? "2px solid #ef4444"
                            : "2px solid #E5E7EB",
        boxShadow: state.isFocused ? "0px 0px 0px #000000" : "none",
        // border: "none",
        "&:hover": {
            //   border: "2px solid #E5E7EB",
            boxShadow: "0px 0px 0px #000000",
        },
        fontSize: "0.875rem",
    }),

    option: (provided, state) => ({
        ...provided,
        // background: state.isSelected ? "#FFFFFF !important" : "#FFFFFF",
        // color: state.isSelected ? "#FFFFFF" : "#FFFFFF !important",
        background: state.isSelected ? "#000 !important" : "#FFFFFF",
        color: state.isSelected ? "#FFFFFF" : "#000 !important",
        "&:hover": {
            background: "#000",
            color: "#ffffff !important",
        },
        fontSize: "0.875rem",
    }),
})


export const getFileTypeFromName = (filename) => {
    // Extract file extension
    const extension = filename.split('.').pop().toLowerCase();
    // Find the matching file type object
    const found = SUPPORTED_FILE_FORMATS.find(format => format.extension === extension);
    if(found){
        return found
    } else {
        return {
            extension: extension,
            Icon: CiFileOn
        }
    }
};


export const getFileNameFromUrl = (url) => {
    try {
      // Create a URL object
      const urlObject = new URL(url);
      // Extract the pathname and split it to get the last segment
      const segments = urlObject.pathname.split('/');
      return segments.pop(); // The last segment is the file name
    } catch (error) {
      console.error("Invalid URL:", error.message);
      return null; // Return null if the URL is invalid
    }
  }