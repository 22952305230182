import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import uiReducer from "./slices/uiSlice";
import languageSlice from "./slices/languageSlice";
import ticketsReducer from "./slices/ticketsSlice";

export default configureStore({
    reducer: {
        user: userReducer,
        ui: uiReducer,
        language: languageSlice,
        tickets: ticketsReducer
    },
    devTools: process.env.NODE_ENV === "development" ? true : false
})