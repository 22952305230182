import React from "react";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from 'react-redux';

function useAPI() {

  const bearer = useSelector(state => state.user.token)

  const Axios = axios.create({
    baseURL: BASE_URL,
    // headers: { Authorization: bearer ? `Bearer ${bearer}` : "" }
  });

  return Axios
}

export default useAPI