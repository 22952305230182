import React from 'react'
import { useNavigate } from 'react-router-dom';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import logo from "../../assets/images/logo3.svg"

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);


function NavBar({setActive, active, handleScrollTo}) {

  const navigate = useNavigate();

  useGSAP(()=>{
    gsap.from(".navBarContent", {
      x: 50,
      duration: 0.4,
      opacity: 0,
      stagger: 0.1,
    })
  })

  function handleNavigate(newsId) {
    navigate(`/`);
  }

  return (
    <div id='navBar' className={`navbarContainer sticky z-30 bg-white h-[60px] md:h-[74px] 3xl:h-[120px] flex justify-between items-center  `}>
        <img onClick={handleNavigate} src={logo} alt="logo" className='h-[60%] navBarContent cursor-pointer' />

        <ul className='hidden md:!flex space-x-4 lg:space-x-4 xl:space-x-16 font-Inter lg_sm:font- lg:font- md:text-[0.78rem] lg_sm:text-[0.8rem] lg:text-[0.85rem] xl:text-[0.95rem] 3xl:text-[1.3rem] 2k:text-[1.4rem] h-[100%] '>
            <div className='flex md:space-x-2 lg_sm:space-x-5 lg:space-x-5 xl:space-x-8 h-[100%]'>
                <div className='navBarContent h-[100%] flex items-center'>
                    <p to="/" onClick={()=>handleScrollTo("vision", 50)} className='cursor-pointer h-[80%] flex items-center hover:underline hover:underline-offset-2  transition-all ease-out'>Vision</p>
                </div>
                <div className='navBarContent h-[100%] flex items-center'>
                    <p to="/" onClick={()=>handleScrollTo("mission")} className='cursor-pointer h-[80%] flex items-center hover:underline hover:underline-offset-2  transition-all ease-out'>Mission</p>
                </div>
                <div className='navBarContent h-[100%] flex items-center'>
                    <p to="/" onClick={()=>handleScrollTo("management", 50)} className='cursor-pointer h-[80%] flex items-center hover:underline hover:underline-offset-2  transition-all ease-out'>Management</p>
                </div>
                <div className='navBarContent h-[100%] flex items-center'>
                    <p to="/" onClick={()=>handleScrollTo("Expertise")} className='cursor-pointer h-[80%] flex items-center hover:underline hover:underline-offset-2  transition-all ease-out'>Expertise</p>
                </div>
                <div className='navBarContent h-[100%] flex items-center'>
                    <p to="/" onClick={()=>handleScrollTo("portfolio")} className='cursor-pointer h-[80%] flex items-center hover:underline hover:underline-offset-2  transition-all ease-out'>Portfolio</p>
                </div>
                <div className='navBarContent h-[100%] flex items-center'>
                    <p to="/" onClick={()=>handleScrollTo("TalentDevelopment")} className='cursor-pointer h-[80%] flex items-center hover:underline hover:underline-offset-2  transition-all ease-out'><p className=''></p> Talent Development</p>
                </div>
            </div>

          <div className='hidden lg:flex navBarContent h-[100%] items-center'>
          {/* navContent     //// use for the scaling on hover: */}
            <p to="/" onClick={()=>handleScrollTo("contact")} className='cursor-pointer  transition-all ease-linear px-4 py-[5px] 2k:py-[8px] 2k:px-5 bg-[#50B5EB] hover:bg-[#326EBC] text-white rounded-full'>CONTACT</p>
          </div>
        </ul>


        <div class="flex  md:hidden text-[1.25rem] group h-[40%] cursor-pointer items-center justify-center transition-transform ease-in-out hover:scale-[1.17] p-2" onClick={()=>setActive(!active)}>
          <div class="space-y-2">
              <span class={`block h-[0.2rem] w-8 origin-center rounded-full bg-blue-900 transition-transform ease-in-out ${active ? 'translate-y-1.5 rotate-45' : ''} `}></span>
              <span class={`block h-[0.2rem] w-8 origin-center rounded-full bg-blue-900 transition-transform ease-in-out ${active ? 'hidden' : 'block'} `}></span>
              <span class={`block h-[0.2rem] w-8 origin-center rounded-full bg-blue-900 transition-transform ease-in-out ${active ? '-translate-y-1.5 -rotate-45' : ''} `}></span>
          </div>

        </div>
    </div>
  )
}

export default NavBar
