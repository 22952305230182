import React, { useEffect, useRef, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import NavBar from '../../components/Navbar/Navbar';
import SideBar from '../../components/Sidebar/Sidebar';
import HeroPage from '../../components/MainPageComponents/HeroPage';
import Vision from '../../components/MainPageComponents/Vision';
import Mission from '../../components/MainPageComponents/Mission';
import Managment from '../../components/MainPageComponents/Managment';
import Accordion from '../../components/MainPageComponents/Accordion';
import Portfolio from "../../components/MainPageComponents/Portfolio"
import TestAndDevelopment from '../../components/MainPageComponents/TestAndDevelopment';
import ContactForm from '../../components/MainPageComponents/ContactForm';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import CookiePolicyBanner from '../../components/CookiePolicyBanner/CookiePolicyBanner';


function MainPage() {
    const [ active, setActive ] = useState(false)
    const disableScrollRef = useRef()



    function scrollToSmoothly(pos, time) {
      var currentPos = window.pageYOffset;
      var start = null;
      if (time == null) time = 500;
      pos = pos + 1;
      time = time + 1;
      window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
          window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
        } else {
          window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
        }
        if (progress < time) {
          window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, pos);
        }
      });
    }

    const handleScrollTo = (id, offset = 0)=>{
        const element = document.getElementById(id)
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = window.scrollY + elementPosition - offset;
            scrollToSmoothly(offsetPosition, 500);
          }
    }

    useEffect(()=>{
      if(active){
        disableBodyScroll(disableScrollRef.current)
      }else{
        enableBodyScroll(disableScrollRef.current)
      }
    }, [active])



  return (
    <div>
        <div className=" App font-default ">
          <OutsideClickHandler
              onOutsideClick={() => {
                  setActive(false)
                  }}
              >
              <NavBar setActive={setActive} active={active}
              handleScrollTo={handleScrollTo}
              />
              <SideBar setActive={setActive} active={active}
              handleScrollTo={handleScrollTo}
              />
          </OutsideClickHandler>
          <div ref={disableScrollRef}>
            <HeroPage />
            <Vision />
            <Mission />
            <Managment />
            <Accordion />
            <Portfolio />
            <TestAndDevelopment />
            <ContactForm />
            <CookiePolicyBanner />
          </div>
        </div>

    </div>
  )
}

export default MainPage
