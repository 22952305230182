import React from "react";
import visionbg from "../../assets/images/vision1.png";
import { motion } from "framer-motion";
import CountUp from "react-countup";

// Variants for each individual Stats component
const itemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// Variants for the parent container (Vision)
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

function Vision() {
  // px-3 xs:px-5  xl:px-36
  return (
    <div className="pt-10 xs:pt-16 lg:pt-24                           px-3 xs:px-5 md:px-16 xl:px-24 3xl:px-52">
      <div className="mx-auto  text-clr-secondary ">
        <motion.h1
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
          }}
          viewport={{
            once: true,
            amount: 0.2,
          }}
          className="vision text-center text-[1.9rem] sm:text-[2rem] md_sm:text-[2.5rem] lg:text-[3rem] 2k:text-[3.3rem] font-bold "
        >
          Number or telling our story
        </motion.h1>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          className="flex justify-center items-center flex-col lg:flex-row lg:justify-items-stretch space-y-9 xs:space-y-14 lg:space-y-0   lg:space-x-12 xl:space-x-14 3xl:space-x-24 mt-8 xs:mt-16 xs:mb-12 lg:mb-16"
        >
          {/*  md:gap-16 xl:gap-20 3xl:gap-36 */}
          <Stats
            number={"6"}
            sign={"inFront"}
            signValue={"+"}
            description={"Active Customers Rate"}
          />
          <Stats
            number={"50"}
            sign={"inBack"}
            signValue={"+"}
            description={"Startup"}
          />
          <Stats
            number={"93"}
            sign={"inBack"}
            signValue={"%"}
            description={"Customer Satisfaction Rate"}
          />
        </motion.div>
      </div>

      <motion.div
        id="vision"
        initial={{
          opacity: 0,
          y: 50,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 1,
        }}
        viewport={{
          once: true,
          amount: 0.3,
        }}
        className="w-full p-10 md:p-14 xl:p-14 xl_lg:p-16 3xl:pl-28 rounded-3xl overflow-hidden bg-cover bg-left md:bg-center                     mt-10 xs:mt-16 lg:mt-24"
        style={{ backgroundImage: `url(${visionbg})` }}
      >
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          className="transform md:w-[90%] lg:w-[70%] xl:w-[65%] 3xl:w-[60%] text-center text-white font-default pr-5 md:pr-10 "
        >
          <motion.h1
            variants={itemVariants}
            viewport={{ once: true, amount: 0.2 }}
            className="font-bold mb-4 text-left text-[2rem] xs:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] 3xl:text-[4.3rem]"
          >
            Vision
          </motion.h1>
          <motion.p
            variants={itemVariants}
            viewport={{ once: true, amount: 0.2 }}
            className="text-left text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem] "
          >
            At <b>Myra Medical,</b> we envision a world where cutting-edge
            transcatheter medical technologies revolutionize cardiovascular and
            radiologic interventions, significantly improving patient outcomes
            and quality of life. Our mission is to be at the forefront of
            innovation, developing advanced medical devices that transform the
            treatment landscape for patients suffering from cardiovascular and
            oncological conditions.
          </motion.p>
          <motion.p
            variants={itemVariants}
            viewport={{ once: true, amount: 0.2 }}
            className="text-left text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem] "
          >
            We are committed to fostering and accelerating the growth of
            startups with pioneering projects in these fields, ensuring their
            groundbreaking solutions reach the market and benefit patients
            worldwide. Together, we strive to create a healthier future through
            relentless innovation, collaboration, and dedication to excellence.
          </motion.p>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Vision;

function Stats(props) {
  return (
    <motion.div
      variants={itemVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      className="vision w-[80%] lg:w-[25%] sm:h-[212px] 2k:h-[300px] px-6 py-6 lg_sm:pb-14 xl:pb-20 flex flex-col items-center justify-center rounded-2xl shadow-[0px_13px_15px_-0px_rgba(0,0,0,0.2)]"
      // style={{flexBasis: "70%"}}
    >
      <p className="text-[3.2rem] xs:text-[3.5rem] sm:text-[3.7rem] md_sm:text-[3.8rem] md:text-[4.2rem] lg_sm:text-[4.6rem] lg:text-[5rem] xl:text-[6rem] 2k:text-[6.3rem] font-bold mb-1 mt-1 font-numbers">
        {props.sign === "inFront" ? props.signValue : ""}
        <CountUp
          start={0}
          end={props.number}
          duration={1.5}
          enableScrollSpy={true}
          scrollSpyDelay={100}
          scrollSpyOnce={true}
        ></CountUp>
        {props.sign === "inBack" ? props.signValue : ""}
      </p>
      <p className="text-[#A1A1AA] text-center font-semibold font-Inter text-[0.75rem] xs:text-[0.9rem] md:text-[1rem] xl:text-[1.12rem] 2k:text-[1.2rem]">
        {props.description}
      </p>
    </motion.div>
  );
}
