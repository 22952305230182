import React, { useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { BiX } from "react-icons/bi";
import { BiPlus } from "react-icons/bi";

const accordionData = [
  {
    id: 0,
    heading: "Virtual Startup Incubator",
    description: (
      <p>
        Our Virtual Startup Incubator offers a unique opportunity for Clients to
        access dedicated Engineering talent embedded within our innovative
        structure. One Project Development Engineer is assigned to work
        exclusively on your project, leveraging our state-of-the-art resources,
        strategic guidance, and technical competencies to drive development and
        ensure success. This consultant option allows you to focus on your core
        vision while benefiting from the expertise of professionals who operate
        under our proven frameworks. From R&D to prototyping, our engineers act
        as an extension of your team, accelerating progress and delivering
        tailored solutions efficiently and effectively. By integrating talented
        people and infrastructure, we empower startups and businesses to scale
        their projects seamlessly, ensuring high-quality outcomes in a
        collaborative, technical environment. Myra Medical supports hiring of
        the right personnel and favors the spin-off of the Startup at the right
        time to grant continuity and success.
      </p>
    ),
  },
  {
    id: 1,
    heading: "Design R&D",
    description: (
      <>
        <p>
          We provide comprehensive support to our clients in the design of both
          active and passive medical devices, including implantable ones, with a
          particular focus on the cardiovascular field. The development of
          innovative medical devices is initiated by conducting a feasibility
          assessment in collaboration with the client. Following this, the
          initial design phase is carried out, incorporating finite element
          analysis (FEA) to create the first functional prototypes of the
          device.
        </p>
        <p>
          Myra Medical’s time-to-cost design approach utilizes high-performance
          biomaterials and advanced manufacturing technologies to produce safe
          and efficient devices. We also have specialized expertise (with
          availability of Clean Room ISO 7 for pre-clinical and clinical devices
          manufacturing) in the manual assembly of medical devices requiring
          manufacturing skills for stitching materials such as fabrics,
          silicones, and biological components like bovine or
          porcine pericardium. These devices are suitable for clinical
          evaluation and certification under MDR (Medical Device Regulation) or
          FDA (Food and Drug Administration) standards and are optimized for
          large-scale industrial production.
        </p>
      </>
    ),
  },
  {
    id: 2,
    heading: "Regulatory and Clinical Programs",
    description: (
      <>
        <p>
          For devices under development, regulatory development plans are
          created that include certification
          costs, execution timelines considering the desired type of
          certification, whether under MDR or FDA requirements. Regulatory
          planning allows for the definition of a detailed testing plan, which
          may involve special tests based on risk analysis or tests in
          accordance with applicable International Standards.
        </p>
        <p>
          We offer assistance to clients in drafting test protocols and reports.
          The tests are conducted through a selected group of certified external
          laboratories. On behalf of the client, we manage the preparation and
          submission of regulatory technical files to obtain approval for market
          authorization. Additionally, Clinical Plans are developed, which
          involve strategic planning of the clinical study,
          including investigation protocol creation, study budgeting, study
          execution, data analysis, and the preparation of the clinical report.
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: "Project Management - Business Plans and P&L",
    description: (
      <>
        <p>
          For each medical device development project, our company offers
          comprehensive project management support, working with the client to
          define the development timeline. This timeline takes into account the
          design, regulatory, and clinical plans. Effective project management
          ensures that critical project activities are closely monitored,
          preventing unnecessary financial resource consumption.
        </p>
        <p>
          As part of its Project Management services, we can, upon client
          request, prepare Business Plans and Profit & Loss (P&L) Plans, which
          can be used in investor presentations. These activities enable startup
          founders to present their project with a clear definition of the
          project timeline, associated costs, and investments, in relation to
          the return on investment (ROI) timeline and the achievement of
          breakeven.
        </p>
      </>
    ),
  },
  {
    id: 4,
    heading: "Due Diligence",
    description: (
      <>
        <p>
        Our company specializes in comprehensive <span className="font-bold">due diligence</span> support for the biomedical sector, 
        with a strong focus on <span className="font-bold">Regulatory Strategic pathway.</span> Regulatory compliance is becoming a strategic 
        factor in assessing the economic value of a company or project, especially with the implementation of MDR.
        </p>
        <p>
        We assist Companies and Venture Capital firms, in the frame of their investment projects, identifying key risks 
        and opportunities by thoroughly evaluating Regulatory pathways for CE marking or FDA approval, R&D strategies and 
        technological feasibility, Existing clinical data and its relevance, Intellectual Property landscape , and competitive positioning.
        </p>
        <p>
        Our expert-driven approach ensures a detailed assessment of both technological and regulatory aspects, enabling informed decisions in 
        investments, mergers, and acquisitions. By analyzing technical documentation from a specialized perspective, we provide critical insights 
        that go beyond compliance and feasibility, offering strategic guidance for company growth and development.
        </p>
      </>
    ),
  },

  {
    id: 5,
    heading: "Patents",
    description: (
      <>
        <p>
          We can provide support to clients with innovative medical device
          development ideas by assisting in the formulation of patenting
          strategies. This service includes the drafting of patent applications
          and the preparation of patent drawings in close collaboration with a
          network of Patent Attorneys specialized in medical devices. Our
          company also helps the client conducting preliminary Freedom to
          Operate (FTO) analyses to identify potential patent conflicts in the
          early stages of development.
        </p>
      </>
    ),
  },
  {
    id: 6,
    heading: "Project Financial Management",
    description: (
      <>
        <p>
          The daily financial management of a project is complex, requiring
          careful attention, particularly in relation to supplier interactions,
          expense reporting, and cash flow management, while ensuring close
          monitoring of financial consumption. Our company supports clients by
          providing easy calculation models that enable comprehensive expense
          control throughout the project.
        </p>
      </>
    ),
  },
  {
    id: 7,
    heading: "Fund Raising",
    description: (
      <>
        <p>
          Fundraising is a complex and demanding activity that founders must
          approach with continuous, serious, and rigorous effort. We offer
          support in the preparation of technical, business, and financial
          presentations for the project and, if necessary, assist the client
          during investor presentations. Additionally, our company can provide
          informational services to help identify suitable Investors aligned
          with the specific type of medical device under development.
        </p>
      </>
    ),
  },
  {
    id: 8,
    heading: "The Project Team",
    description: (
      <>
        <p>
          Myra Medical manages the development projects of startups and medical
          devices through direct collaboration with in-house professionals and a
          solid network of high-experience consultants and service
          providers (certified laboratories).
        </p>
        <p>
          A team of biomedical engineers supports clients across various
          development phases (feasibility, preclinical, clinical, and regulatory
          certification), and each project is assigned to a dedicated Project
          Leader who serves as the direct interface with the client.
        </p>
      </>
    ),
  },
];

// Variants for each individual Stats component
const itemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// Variants for the parent container (Vision)
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

function Accordion() {
  const [selected, setSelected] = useState(null);

  const handleToggle = (id) => {
    setSelected((prevSelected) => (prevSelected === id ? null : id));
  };

  return (
    <div>
      <div
        id="accordion"
        className="bg-clr-accordion flex justify-center py-[2.5rem] md:py-[3rem] lg:py-[4.5rem]          px-3 xs:px-5 md:px-16 xl:px-24 3xl:px-52"
      >
        <div className="">
          <motion.ul
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            className="space-y-6 md:space-y-8 lg:space-y-10 xl:space-y-14 3xl:space-y-12    "
          >
            {accordionData.map((item) => (
              <AccordionItem
                key={item.id}
                id={item.id}
                heading={item.heading}
                description={item.description}
                isSelected={selected === item.id}
                onToggle={handleToggle}
              />
            ))}
          </motion.ul>
        </div>
      </div>
    </div>
  );
}

function AccordionItem({ id, heading, description, isSelected, onToggle }) {
  const contentHeight = useRef();

  return (
    <motion.li
      variants={itemVariants}
      viewport={{ once: true, amount: 0.2 }}
      className="group bg-white rounded-[0.9rem] md:rounded-[1.2rem] xl:rounded-[2rem] 3xl:rounded-[2.3rem] text-clr-primary font-Poppins "
    >
      <button
        type="button"
        className="w-full px-6 lg:px-8 py-3 lg:py-4 xl:py-5 3xl:py-7 text-left flex items-center justify-between space-x-3"
        onClick={() => onToggle(id)}
      >
        <span className="w-[90%] font-medium text-[1.1rem] md:text-[1.25rem] lg:text-[1.4rem] xl:text-[1.9rem] 3xl:text-[2.2rem] md:tracking-wide">
          {heading}
        </span>
        <span
          className={`ml-auto group-hover: ${
            isSelected
              ? "bg-white text-clr-primary border-2 border-clr-primary lg_sm:group-hover:text-white lg_sm:group-hover:bg-clr-primary"
              : "text-white bg-clr-primary lg_sm:group-hover:bg-white lg_sm:group-hover:text-clr-primary lg_sm:group-hover:border-2 lg_sm:group-hover:border-clr-primary"
          } text-[1.5rem] md:text-[2rem] xl:text-[2.7rem] flex items-center justify-center rounded-full w-[28px] h-[28px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] xl:h-[55px] xl:w-[55px]`}
        >
          {/* <FontAwesomeIcon icon={isSelected
            ?
            faXmark
            :
            faPlus
            }
          /> */}
          {
            isSelected ?
            <BiX />
            :
            <BiPlus />
          }
        </span>
      </button>
      <div
        ref={contentHeight}
        className={`transition-all duration-300 ease-linear overflow-hidden text-black text-[0.9rem] xs:text-[1.1rem] lg:text-[1.15rem] xl:text-[1.375rem] 3xl:text-[1.5rem] font-default`}
        style={
          isSelected
            ? { height: contentHeight.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="px-6 lg:px-8 pb-3 lg:pb-4 xl:pb-5 3xl:pb-7">
          {description}
        </div>
      </div>
    </motion.li>
  );
}

export default Accordion;
