import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

function CookiePolicyBanner() {

  const cookies =  JSON.parse(localStorage.getItem("myr_medical_cookie_accepted"));

  const [accepted, setAccepted] = useState((cookies === true || cookies !== null) ? cookies : false);

  function handleCookieBannerClose() {
    localStorage.setItem("myr_medical_cookie_accepted", JSON.stringify(true));
    setAccepted(true);
  }

  return (
    <AnimatePresence mode="wait">
      {!accepted && (
        <motion.div
          initial={{ y: 200 }}
          exit={{
            // opacity: 0,
            transform: "translateY(300px)",
          }}
          animate={{
            transform: "translateY(0px)",
            opacity: 1,
          }}
          transition={{
            duration: 0.8,
            ease: "easeInOut",
          }}
          key="accepted"
        //   onAnimationComplete={() => {
        //     // After the exit animation completes, set Accepted to false
        //     // if (!accepted) {
        //     //   setAccepted(false);
        //     // }
        //   }}
          className="sticky bottom-0 p-3 py-5 md:p-8 w-full bg-clr-ternity flex flex-col gap-5 items-center md:flex-row md:gap-7 text-[0.85rem] md:text-[0.9rem] shadow-[0px_-10px_5px_0px_rgba(0,0,0,0.1)]"
        >
          <div className="space-y-3 md:space-y-5 text-white">
            <p>
              This website uses essential technical cookies for its
              functionality, as well as analytical cookies, including anonymized
              third-party cookies, to perform aggregated statistical analysis.
              By closing this banner, you agree to the processing of your
              personal data through the specified categories of cookies.
            </p>
            <p>
              For more details about the cookies in use, please refer to our
              Cookie Policy.
            </p>
          </div>

          <div className="flex gap-5">
            <button
              onClick={handleCookieBannerClose}
              className="bg-white px-4 py-2 rounded-xl cursor-pointer transition-all ease-linear whitespace-nowrap"
            >
              Close
            </button>
            <Link
              to="/cookie-policy"
              className="bg-white px-4 py-2 rounded-xl cursor-pointer transition-all ease-linear whitespace-nowrap"
            >
              Cookie Policy
            </Link>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default CookiePolicyBanner;
