import { createSlice } from "@reduxjs/toolkit";
import { getAppLanguage } from "../../utils";
import { LANGUAGE_OPTIONS } from "../../constants";

const initialState = getAppLanguage();

const languageSlice = createSlice({
    initialState,
    name: "language",
    reducers: {
        set_language: (state, action) => {
            state = action.payload;
            return state
        }
    }
})

export default languageSlice.reducer;

export const {
    set_language
} = languageSlice.actions;
