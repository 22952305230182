import React from 'react'
import UnauthRoutes from './UnauthRoutes';


const BaseRoutes = () => {
  return (
    // user ?
    //   user.role === ROLE_ADMIN.value ?
    //   <AdminRoutes/>
    //   :user.role === ROLE_WAITER.value ?
    //   <WaiterRoutes/>
    //   :
    //     null
    // :
    <UnauthRoutes />
  )
}

export default BaseRoutes