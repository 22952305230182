import { createSlice } from "@reduxjs/toolkit";
import { TICKETS_VIEW_NOT_SELECTED } from "../../constants";

const initialState = {
    active_ticket: null,
    ticket_to_load: null,
    list: [],
    tickets_menu: true,
    actions_menu: true,
};

const ticketsSlice = createSlice({
    initialState,
    name: "tickets",
    reducers: {
        set_ticket_active: (state, action) => {
            state.active_ticket = action.payload;
            return state
        },
        set_ticket_to_load: (state, action) => {
            state.ticket_to_load = action.payload;
            return state
        },
        set_tickets: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        reset_tickets: (state, action) => {
            state = initialState
            return state
        },
    }
})

export default ticketsSlice.reducer;

export const {
    set_ticket_active,
    set_ticket_to_load,
    set_tickets,
    reset_tickets
} = ticketsSlice.actions;
