import React, {Suspense} from 'react';
import {Routes, Route, Navigate} from "react-router-dom";

import MainPage from '../../pages/Main/MainPage';
import CookiePolicyPage from '../../pages/Main/CookiePolicyPage';

const UnauthRoutes = () => {
  return (
        <Routes>
            <Route
                path="/"
                element={<MainPage />}
            />
            <Route
                path="/cookie-policy"
                element={<CookiePolicyPage />}
            />
        </Routes>
  )
}

export default UnauthRoutes